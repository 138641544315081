<div class="parent">
  <div class="top">
    <div class="contact-jfw">
      <a href="/email-us">
        <mat-icon>mail</mat-icon>
        <span>EMAIL</span>
      </a>
      <div class="vertical-line"></div>
      <a href="tel:866-783-5767" class="call-jfw">
        <mat-icon>call</mat-icon>
        <span>CALL</span>
      </a>
    </div>
    <div class="social-media">
      <ul>
        <li>
          <a href="https://www.instagram.com/jimsformalwear/" target="_blank">
            <img
              src="assets/images/icons/external-logos/instagram.png"
              alt="Jim's Formal Wear Instagram"
            />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/jimsformalwearco/" target="_blank">
            <img
              src="assets/images/icons/external-logos/facebook.png"
              alt="Jim's Formal Wear Facebook"
            />
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@jimsformalwear" target="_blank">
            <img
              src="assets/images/icons/external-logos/tiktok.png"
              alt="Jim's Formal Wear Tiktok"
            />
          </a>
        </li>
        <li>
          <a href="https://www.pinterest.com/jimsformalwear/" target="_blank">
            <img
              src="assets/images/icons/external-logos/pinterest.png"
              alt="Jim's Formal Wear Pinterest"
            />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/@JimsFormalWear" target="_blank">
            <img
              src="assets/images/icons/external-logos/youtube.png"
              alt="Jim's Formal Wear YouTube"
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="bottom">
    <div class="desktop-nav">
      <div>
        <h3>SUPPORT</h3>
        <nav>
          <ul>
            <li *ngFor="let navListItem of supportNav">
              <a
                *ngIf="navListItem.linkType === linkType.Route"
                routerLink="{{ navListItem.link }}"
                >{{ navListItem.title }}</a
              >
              <a
                *ngIf="navListItem.linkType === linkType.Url"
                href="{{ navListItem.link }}"
                >{{ navListItem.title }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
      <div>
        <h3>FOR RETAILERS</h3>
        <nav>
          <ul>
            <li *ngFor="let navListItem of forRetailersNav">
              <a
                *ngIf="navListItem.linkType === linkType.Route"
                routerLink="{{ navListItem.link }}"
                >{{ navListItem.title }}</a
              >
              <a
                *ngIf="navListItem.linkType === linkType.Url"
                href="{{ navListItem.link }}"
                >{{ navListItem.title }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
      <div>
        <h3>ABOUT US</h3>
        <nav>
          <ul>
            <li *ngFor="let navListItem of aboutUsNav">
              <a
                *ngIf="navListItem.linkType === linkType.Route"
                routerLink="{{ navListItem.link }}"
                >{{ navListItem.title }}</a
              >
              <a
                *ngIf="navListItem.linkType === linkType.Url"
                href="{{ navListItem.link }}"
                >{{ navListItem.title }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <mat-accordion class="mobile-nav">
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>SUPPORT</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-action-list>
          <ng-container *ngFor="let navListItem of supportNav">
            <a
              *ngIf="navListItem.linkType === linkType.Route"
              mat-list-item
              routerLink="{{ navListItem.link }}"
            >
              {{ navListItem.title }}
            </a>
            <a
              *ngIf="navListItem.linkType === linkType.Url"
              mat-list-item
              href="{{ navListItem.link }}"
            >
              {{ navListItem.title }}
            </a>
          </ng-container>
        </mat-action-list>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>FOR RETAILERS</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-action-list>
          <ng-container *ngFor="let navListItem of forRetailersNav">
            <a
              *ngIf="navListItem.linkType === linkType.Route"
              mat-list-item
              routerLink="{{ navListItem.link }}"
            >
              {{ navListItem.title }}
            </a>
            <a
              *ngIf="navListItem.linkType === linkType.Url"
              mat-list-item
              href="{{ navListItem.link }}"
            >
              {{ navListItem.title }}
            </a>
          </ng-container>
        </mat-action-list>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>ABOUT US</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-action-list>
          <ng-container *ngFor="let navListItem of aboutUsNav">
            <a
              *ngIf="navListItem.linkType === linkType.Route"
              mat-list-item
              routerLink="{{ navListItem.link }}"
            >
              {{ navListItem.title }}
            </a>
            <a
              *ngIf="navListItem.linkType === linkType.Url"
              mat-list-item
              href="{{ navListItem.link }}"
            >
              {{ navListItem.title }}
            </a>
          </ng-container>
        </mat-action-list>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="jfw-info">
      <img
        src="assets/images/jfw-logos/logo.png"
        alt="Logo for Jim's Formal Wear"
      />
      <h3>America's Most Trusted Tuxedo Source</h3>
      <p style="margin-bottom: 20px">Quality Service Since 1964</p>
      <p style="margin-bottom: 5px">© 2023 Jim's Formal Wear, LLC.</p>
      <p>All Rights Reserved</p>
      <p *ngIf="libVersion">Version: {{ libVersion }}</p>
      <p *ngIf="buildNumber">Build Number: {{ buildNumber }}</p>
      <p *ngIf="buildDate">Build Date: {{ buildDate }}</p>
    </div>
  </div>
</div>
