<main class="parent">
  <section class="main-banner">
    <div class="main-banner-image">
      <!-- <img
        class="image"
        src="assets/images/banners/home-page/home-page-main-image-1152x600.webp"
      /> -->
    </div>
    <div class="main-banner-text-container">
      <div class="main-banner-text-content">
        <h1>Find Your Ideal<br />Suit or Tuxedo</h1>
        <p><b>RENT IT</b> or <b>KEEP IT</b></p>
        <!-- <button
          mat-flat-button
          color="primary"
          (click)="getStarted()"
          aria-label="Get Started with Your Event"
        >
          GET STARTED
        </button> -->
        <button
          class="home-page-button"
          style="margin-bottom: 1rem"
          mat-flat-button
          color="primary"
          routerLink="/ensembles/suits-and-tuxedos"
        >
          VIEW STYLES
        </button>
      </div>
    </div>
  </section>

  <section class="plan-your-event">
    <!-- <h2>Plan Your Event</h2> -->
    <h2>Service You Can Trust</h2>
    <div class="plan-event-container">
      <span
        >With over 22 million customers served, our commitment to quality isn’t
        just a phase. It’s grounded in 60+ years of experience. Let JFW help you
        look and feel your best.</span
      >
    </div>
    <!-- <button mat-flat-button color="primary" (click)="getStarted()">
      GET STARTED
    </button> -->
  </section>

  <section class="four-step-process">
    <h2>Our Four Step Process</h2>
    <div class="line"></div>
    <div class="process-parent">
      <div class="process-child">
        <div class="circle">
          <span>1</span>
        </div>
        <!-- <img
          src="assets/images/icons/home-page/style.png"
          alt="Four Step Process - Step One: Select Style"
        /> -->
        <h3>SELECT YOUR STYLE</h3>
        <p>
          Browse pre-styled suits and tuxedos. Then customize your look with
          your choice of accessories.
        </p>
      </div>
      <div class="process-child">
        <div class="circle">
          <span>2</span>
        </div>
        <!-- <img
          src="assets/images/icons/home-page/event.png"
          alt="Four Step Process - Step Two: Setup an Event"
        /> -->
        <h3>SET UP YOUR EVENT</h3>
        <p>
          Save your customized looks and event details using our Event Manager.
        </p>
      </div>
      <div class="process-child">
        <div class="circle">
          <span>3</span>
        </div>
        <!-- <img
          src="assets/images/icons/home-page/fit.png"
          alt="Four Step Process - Step Three: Find Your Fit"
        /> -->
        <h3>FIND YOUR PERFECT FIT</h3>
        <p>
          Use our Digital Measurement Tool online for personalized size
          recommendations.
        </p>
      </div>
      <div class="process-child">
        <div class="circle">
          <span>4</span>
        </div>
        <!-- <img
          src="assets/images/icons/home-page/order.png"
          alt="Four Step Process - Step Four: Receive Your Order"
        /> -->
        <h3>RECEIVE YOUR ORDER</h3>
        <p>Have your order shipped to your home, office, or destination.</p>
      </div>
    </div>
  </section>

  <section class="trending-styles">
    <h2>Trending Styles</h2>
    <app-carousel
      #carousel
      ariaLabel="Carousel of trending styles"
      itemMargin="30px"
    >
      <ng-container *ngFor="let ensemble of ensembles$ | async; let i = index">
        <ng-container *appCarouselItem>
          <mat-card>
            <a
              [routerLink]="[
                '/ensemble',
                ensemble.ensembleCode,
                'ensembleGroupCode',
                ensemble.ensembleGroupCode
              ]"
            >
              <div class="trending-img">
                <!-- <img
                  [ngSrc]="getImage(ensemble).src"
                  [alt]="'trending: ' + i + ' - ' + getImage(ensemble).alt"
                  fill
                /> -->
              </div>
            </a>
            <mat-card-footer>
              <mat-card-title>{{ getDesigner(ensemble) }}</mat-card-title>
              <mat-card-subtitle>
                <a
                  [routerLink]="[
                    '/ensemble',
                    ensemble.ensembleCode,
                    'ensembleGroupCode',
                    ensemble.ensembleGroupCode
                  ]"
                  >{{ ensemble.title }}</a
                >
              </mat-card-subtitle>
            </mat-card-footer>
          </mat-card>
        </ng-container>
      </ng-container>
    </app-carousel>
  </section>

  <!-- <section class="shop-by-occasion">
    <h2>Shop By Occasion</h2>
    <div class="occasion-row">
      <mat-card appearance="outlined">
        <a href="/real-people#weddings">
          <img
            class="image"
            mat-card-image
            src="assets/images/banners/home-page/home-page-shop-by-occasion-weddings-550x400.webp"
          />
          <mat-card-footer>
            <mat-card-title>WEDDINGS</mat-card-title>
          </mat-card-footer>
        </a>
      </mat-card>
      <mat-card appearance="outlined">
        <a href="/real-people#proms-formals">
          <img
            class="image"
            mat-card-image
            src="assets/images/banners/home-page/home-page-shop-by-occasion-proms-formals-550x400.webp"
          />
          <mat-card-footer>
            <mat-card-title>PROMS & FORMALS</mat-card-title>
          </mat-card-footer>
        </a>
      </mat-card>
    </div>
    <div class="occasion-row">
      <mat-card appearance="outlined">
        <a href="/real-people#special-events">
          <img
            class="image"
            mat-card-image
            src="assets/images/banners/home-page/home-page-shop-by-occasion-special-events-550x400.webp"
          />
          <mat-card-footer>
            <mat-card-title>SPECIAL EVENTS</mat-card-title>
          </mat-card-footer>
        </a>
      </mat-card>
      <mat-card appearance="outlined">
        <a href="/real-people#quinceaneras">
          <img
            class="image"
            mat-card-image
            src="assets/images/banners/home-page/home-page-shop-by-occasion-quince-550x400.webp"
          />
          <mat-card-footer>
            <mat-card-title>QUINCEAÑERAS</mat-card-title>
          </mat-card-footer>
        </a>
      </mat-card>
    </div>
  </section> -->

  <!-- <section class="rental-purchase-options">
    <h2>Flexible Rental And Purchase Options</h2>
    <div class="options">
      <mat-card appearance="outlined">
        <img
          mat-card-image
          src="assets/images/banners/home-page/home-page-rental-purchase-options-in-store-550x400.webp"
        />
        <mat-card-footer>
          <mat-card-title> IN-STORE </mat-card-title>
          <span>Rent or Buy From 5000 Locations Nationwide.</span>
          <mat-card-actions>
            <button mat-flat-button color="primary" routerLink="/store-locator">
              Find Store
            </button>
          </mat-card-actions>
        </mat-card-footer>
      </mat-card>
      <mat-card appearance="outlined">
        <img
          mat-card-image
          src="assets/images/banners/home-page/home-page-rental-purchase-options-direct-delivery-550x400.webp"
        />
        <mat-card-footer>
          <mat-card-title>DIRECT DELIVERY</mat-card-title>
          <span> Order In-Store, Ship To Your Door. </span>
          <mat-card-actions>
            <button mat-flat-button color="primary">LEARN MORE</button>
          </mat-card-actions>
        </mat-card-footer>
      </mat-card>
      <mat-card appearance="outlined">
        <img
          mat-card-image
          src="assets/images/banners/home-page/home-page-rental-purchase-options-online-550x400.webp"
        />
        <mat-card-footer>
          <mat-card-title>ONLINE</mat-card-title>
          <span>Rent Or Buy Conveniently From Home.</span>
          <mat-card-actions>
            <button mat-flat-button color="primary" (click)="getStarted()">
              GET STARTED
            </button>
          </mat-card-actions>
        </mat-card-footer>
      </mat-card>
    </div>
  </section> -->

  <section class="get-inspired">
    <h2>Get Inspired By <br class="mobile-only" />Our Community</h2>
    <div class="get-inspired-container">
      <p>
        Tag us on Instagram <b>&#64;jimsformalwear</b> using
        <b>#jimsformalwear</b> for a chance to be featured.
      </p>
    </div>

    <div class="inspire-images">
      <div class="temp-single-image">
        <a href="/real-people">
          <!-- <img
            class="image"
            src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-collage-1745x1390.webp"
          /> -->
        </a>
      </div>
      <!-- <div class="column-1">
          <div class="half-size">
            <img class="first" src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-tan-skating-325x325.webp">
            <img class="second" src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-tan-mustache-325x325.webp">
          </div>
          <div class="full-size">
            <img src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-lavender-group-680x680.webp">
          </div>
          <div class="half-size">
            <img src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-heather-gray-kiss-325x325.webp">
            <img src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-tan_ivy-325x325.webp">
          </div>
        </div>

        <div class="column-2">
          <div style="display: flex">
            <div class="full-size" style="margin-right: 30px;">
              <img src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-lite-blue-group-680x680.webp">
            </div>

            <div class="half-size"style="display: flex; flex-direction: column;">
              <img class="first" src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-disco-ball-325x325.webp">
              <img class="second" src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-tan-mustache-325x325.webp">
            </div>
          </div>

          <div style="display: flex;">
            <div class="half-size"style="display: flex; flex-direction: column; ">
              <img class="first" src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-blue-flowers-325x325.webp">
              <img class="second" src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-burgundy-plane-325x325.webp">
            </div>
            <div class="full-size" style="margin-left: 30px;">
              <img src="assets/images/banners/home-page/inspiration/home-page-inspo-page-images-girls-laughing-680x680.webp">
            </div>
          </div>
        </div> -->
    </div>

    <!-- <button mat-flat-button color="primary" routerLink="/real-people">
      GET INSPIRED
    </button> -->
    <button
      class="home-page-button"
      mat-flat-button
      color="primary"
      routerLink="/ensembles/suits-and-tuxedos"
    >
      BROWSE STYLES
    </button>
  </section>
</main>
