<div class="header">
  <div class="left-side">
    <a routerLink="/home" tabindex="0">
      <img
        src="assets/images/jfw-logos/logo.png"
        alt="Logo for JFW - Click to Navigate Home"
        class="logo"
      />
    </a>

    <div class="vertical-line"></div>

    <div class="title-and-nav">
      <span class="title">
        <span>Jim's Formal Wear</span>
      </span>

      <nav>
        <div
          class="header-link"
          [matMenuTriggerFor]="ocMenu"
          #ocMenuTrigger="matMenuTrigger"
          tabindex="0"
          aria-expanded="ocMenuTrigger.menuOpen"
          (keydown)="expandMenu($event, 'ocMenu')"
        >
          <span>Suits & Tuxedos</span>
          @if (ocMenuTrigger.menuOpen) {
            <mat-icon>keyboard_arrow_up</mat-icon>
          } @else {
            <mat-icon>keyboard_arrow_down</mat-icon>
          }
        </div>

        <mat-menu #ocMenu="matMenu">
          <div class="our-collection-items">
            <div class="column">
              <div class="collection-header">Collection</div>

              @for (
                item of rentalCategoryItems;
                track [item.route, item.displayName];
                let index = $index
              ) {
                @if (item.route === "suits-and-tuxedos") {
                  <button
                    tabindex="0"
                    mat-menu-item
                    (keydown.shift.tab)="
                      index === 0 ? closeMenu($event, 'ocMenu') : undefined
                    "
                    (keydown)="stopPropagation($event)"
                    (click)="suitsTuxedosNavigation('/ensembles/' + item.route)"
                  >
                    {{ item.displayName }}
                  </button>
                } @else {
                  <button
                    tabindex="0"
                    (keydown)="stopPropagation($event)"
                    mat-menu-item
                    [routerLink]="['styles', item.route]"
                  >
                    {{ item.displayName }}
                  </button>
                }
              }

              <br />
              <div class="collection-header">Rent Or Buy</div>
              <button
                mat-menu-item
                (click)="ocMenuTrigger.closeMenu()"
                routerLink="/ways-to-rent-or-buy"
              >
                Ways to Rent Or Buy
              </button>
              <br />

              <div class="collection-header">Special Sizes</div>
              <button
                mat-menu-item
                (click)="ocMenuTrigger.closeMenu()"
                routerLink="/big-and-tall"
              >
                Big & Tall
              </button>

              <button
                mat-menu-item
                (click)="ocMenuTrigger.closeMenu()"
                routerLink="/kids"
              >
                Kids
              </button>
            </div>
          </div>
        </mat-menu>

        <div
          class="header-link"
          [matMenuTriggerFor]="hiwMenu"
          #hiwMenuTrigger="matMenuTrigger"
          tabindex="0"
          aria-expanded="hiwMenuTrigger.menuOpen"
          (keydown)="expandMenu($event, 'hiwMenu')"
        >
          <span>How It Works</span>
          @if (hiwMenuTrigger.menuOpen) {
            <mat-icon>keyboard_arrow_up</mat-icon>
          } @else {
            <mat-icon>keyboard_arrow_down</mat-icon>
          }
        </div>

        <mat-menu #hiwMenu="matMenu">
          @for (
            item of howItWorksNav;
            track [item.link, item.title];
            let index = $index
          ) {
            <button
              tabindex="0"
              routerLink="{{ item.link }}"
              (keydown.shift.tab)="
                index === 0 ? closeMenu($event, 'hiwMenu') : undefined
              "
              (keydown)="
                index !== howItWorksNav.length - 1
                  ? stopPropagation($event)
                  : undefined
              "
              mat-menu-item
            >
              {{ item.title }}
            </button>
          }
        </mat-menu>

        <div
          class="header-link"
          [matMenuTriggerFor]="inspirationMenu"
          #inspirationMenuTrigger="matMenuTrigger"
          tabindex="0"
          aria-expanded="inspirationMenuTrigger.menuOpen"
          (keydown)="expandMenu($event, 'inspirationMenu')"
        >
          <span>Inspiration</span>
          @if (inspirationMenuTrigger.menuOpen) {
            <mat-icon>keyboard_arrow_up</mat-icon>
          } @else {
            <mat-icon>keyboard_arrow_down</mat-icon>
          }
        </div>

        <mat-menu #inspirationMenu="matMenu">
          @for (
            item of inspirationNav;
            track [item.link, item.title];
            let index = $index
          ) {
            @if (item.linkType === linkType.Route) {
              <button
                tabindex="0"
                routerLink="{{ item.link }}"
                (keydown.shift.tab)="
                  index === 0 ? closeMenu($event, 'inspirationMenu') : undefined
                "
                (keydown)="
                  index !== inspirationNav.length - 1
                    ? stopPropagation($event)
                    : undefined
                "
                mat-menu-item
              >
                {{ item.title }}
              </button>
            }
            @if (item.linkType === linkType.Url) {
              <a
                href="{{ item.link }}"
                mat-menu-item
                (keydown)="
                  index !== inspirationNav.length - 1
                    ? stopPropagation($event)
                    : undefined
                "
                [target]="item.target"
              >
                {{ item.title }}
              </a>
            }
          }
        </mat-menu>

        <div class="header-link">
          <a routerLink="/store-locator" routerLinkActive="active">
            <span>Find a Store</span>
          </a>
        </div>

        <div class="header-link">
          <a href="https://shop.jimsformalwear.com/" target="_blank">
            <span>Buy</span>
          </a>
        </div>

        @if (alphaMode) {
          <div class="header-link">
            <a [matMenuTriggerFor]="alphaMenu" routerLinkActive="active">
              <span>Alpha</span>
            </a>
          </div>
        }

        <mat-menu #alphaMenu="matMenu">
          @for (navListItem of alphaNav; track navListItem.link) {
            <button routerLink="{{ navListItem.link }}" mat-menu-item>
              {{ navListItem.title }}
            </button>
          }
        </mat-menu>
      </nav>
    </div>
  </div>

  <div class="right-side">
    @if (isBrowser && user() !== undefined) {
      <app-search-desktop></app-search-desktop>

      @if (user() !== null && isLoggedIn()) {
        <button
          mat-icon-button
          class="account-button"
          routerLink="/my-account/events"
          title="My Account select to navigate to account information and events"
          rel="nofollow"
        >
          <div>
            <span>{{ firstLetter() }}</span>
          </div>
        </button>
      } @else {
        <button
          mat-icon-button
          title="Guest - Click to Login"
          (click)="onAnon()"
          rel="nofollow"
        >
          <mat-icon>account_circle</mat-icon>
        </button>
        @if (!hideGetStarted) {
          <button
            mat-flat-button
            color="primary"
            title="Get Started"
            (click)="onGetStarted()"
            rel="nofollow"
          >
            Get Started
          </button>
        }
      }
    }
  </div>
</div>
