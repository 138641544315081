import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Performance } from '@angular/fire/performance';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AuthService,
  EnsembleService,
  NavigationService,
  StyleService,
} from '@jfw-library/ecommerce/core';
import { CanonicalService } from '@jfw-library/ecommerce/shared';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { ECOMMERCE_MAIN_ENVIRONMENT } from './core/tokens/ecommerce-main-environment.token';

// trigger update 95
// deploy changes

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('skipRefHeader') skipRefHeader!: ElementRef;
  @ViewChild('skipRefMain') skipRefMain!: ElementRef;
  @ViewChild('skipRefFooter') skipRefFooter!: ElementRef;

  subscription = new Subscription();
  sidenavPosition: 'start' | 'end' = 'start';
  showHeaderNav = false;
  showPlpNav = false;
  showCustomizeLookNav = false;
  collapsableHeader = false;
  hideHeader = false;
  alphaMode = false;
  msg = '';
  screenWidth = 0;
  currentRoute = '';
  disableHeaderFooter = false;
  routerCss = '';
  prodModeSettingsError: boolean = false;

  private environment = inject(ECOMMERCE_MAIN_ENVIRONMENT);
  maintenance = this.environment.maintenance;

  private authService = inject(AuthService);
  readonly user = toSignal(this.authService.user$);
  private document = inject(DOCUMENT);
  private performance = inject(Performance); // needed for Firebase performance monitoring

  constructor(
    private ensembleService: EnsembleService,
    private metaTagService: Meta,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private styleService: StyleService,
    private titleService: Title,
    private canonicalService: CanonicalService,
  ) {
    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.url.substring(0, event.url.indexOf('?'));

          if (this.currentRoute.trim() === '') {
            this.currentRoute = event.url;
          }

          if (
            this.currentRoute.toLowerCase() !== '/home' &&
            this.currentRoute.toLowerCase() !== '/logout-proxy' &&
            this.currentRoute.toLowerCase() !== '/prod' &&
            this.currentRoute.toLowerCase() !== '/v2login'
          ) {
            localStorage.setItem(
              'lastRoute',
              encodeURIComponent(this.currentRoute),
            );
          }

          this.disableHeaderFooter = this.isHeaderFooterHidden(
            this.currentRoute,
          );

          if (!this.isFullScreen(event.urlAfterRedirects)) {
            this.routerCss = 'center';
          } else {
            this.routerCss = '';
          }
        }
      }),
    );

    this.subscription.add(
      this.route.queryParamMap.subscribe((params) => {
        // console.log('App Component > params', params);
        const customLinkId = params.get('customLinkId');
        if (customLinkId) {
          console.log('Setting customLinkId:', customLinkId);
          localStorage.setItem('customLinkId', customLinkId);
        }
      }),
    );
  }

  ngOnInit(): void {
    this.injectGoogleTagManagerConfig();

    // this.setMetaTags();

    console.log('%cApp_Component_OnInit', 'background-color:black;color:white');

    this.subscription.add(
      this.route.queryParams.subscribe({
        next: (params) => {
          if (params.msg === 'noaccess') {
            this.msg = 'You do not have access to the requested page.';
          } else {
            this.msg = '';
          }

          let alphaModeParam: string = '';
          let headerThemeParam: string = '';

          if (params.alpha) {
            alphaModeParam = params.alpha;
          }

          if (params.headerTheme) {
            headerThemeParam = params.headerTheme;
          }

          if (headerThemeParam) {
            localStorage.setItem('headerTheme', headerThemeParam);
          }

          if (
            alphaModeParam === 'on' ||
            alphaModeParam === '1' ||
            alphaModeParam.toLowerCase() === 'true'
          ) {
            this.alphaMode = true;
            localStorage.setItem('alphaMode', 'true');
          }

          if (
            alphaModeParam === 'off' ||
            alphaModeParam === '0' ||
            alphaModeParam.toLowerCase() === 'false'
          ) {
            this.alphaMode = false;
            localStorage.setItem('alphaMode', 'false');
          }

          if (localStorage.getItem('alphaMode') === 'true') {
            this.alphaMode = true;
          }
        },
      }),
    );

    this.subscription.add(
      this.navigationService.showHeaderNav.subscribe({
        next: (value) => {
          this.closeAllSidenavs();
          this.showHeaderNav = value;
          if (this.showHeaderNav) {
            this.sidenavPosition = 'start';
            this.sidenav.open();
          } else if (this.sidenav?.opened) {
            this.sidenav.close();
          }
        },
      }),
    );

    this.subscription.add(
      this.navigationService.showPlpNav.subscribe({
        next: (value) => {
          this.closeAllSidenavs();
          this.showPlpNav = value;
          if (this.showPlpNav) {
            this.sidenavPosition = 'end';
            this.sidenav.open();
          } else if (this.sidenav?.opened) {
            this.sidenav.close();
          }
        },
      }),
    );

    this.subscription.add(
      this.navigationService.showCustomizeLookNav$.subscribe({
        next: (value) => {
          this.closeAllSidenavs();
          this.showCustomizeLookNav = value;
          if (this.showCustomizeLookNav) {
            this.sidenavPosition = 'end';
            this.sidenav.open();
          } else if (this.sidenav?.opened) {
            this.sidenav.close();
          }
        },
      }),
    );

    this.subscription.add(
      this.navigationService.collapsableHeader.subscribe({
        next: (value) => (this.collapsableHeader = value),
      }),
    );

    console.log('Environment: ' + environment.envVersion);
    console.log('MeasurementId: ' + environment.firebaseConfig.measurementId);
    console.log('Build test ng15_3');
  }

  ngAfterViewInit(): void {
    // console.log(typeof this.document.head?.prepend)

    // this.injectGoogleAnalyticsConfig();

    if (this.sidenav !== undefined && this.sidenav.closedStart !== undefined) {
      this.subscription.add(
        this.sidenav.closedStart.subscribe({
          next: () => {
            this.showHeaderNav = false;
            this.showPlpNav = false;
          },
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public skipMain(event: any, location: string): void {
    event.preventDefault();

    let main = this.document.getElementsByClassName(location);
    this.findFirstFocusableElement(main);
  }

  private findFirstFocusableElement(element: HTMLCollectionOf<Element>): any {
    let length = element.length;
    let childElementIndex = 0;
    while (childElementIndex < length) {
      let child = element.item(childElementIndex);
      if (child) {
        let childLength = child?.children.length;
        if (childLength > 0) {
          let el: any = child.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
          );
          for (let i = 0; i < el.length; i++) {
            if (
              el[i].style.display !== 'none' &&
              el[i].style.visibility !== 'hidden'
            ) {
              el[i].focus();
              break;
            }
          }
        }
      }
      childElementIndex++;
    }
    return;
  }

  // Used to add google analytics script tag into index.html (Note dynamic approach to configure MeasurementId using environment variables)
  // Commented out since we will add tags using GTM below function.
  // injectGoogleAnalyticsConfig(): void {
  //   let measurementId = environment.firebaseConfig.measurementId;
  //   let gtmScript = this.renderer.createElement('script');
  //   let gtagScript = this.renderer.createElement('script');
  //   gtmScript.setAttribute('async', 'true');
  //   gtmScript.setAttribute(
  //     'src',
  //     `https://www.googletagmanager.com/gtag/js?id=${measurementId}`
  //   );
  //   gtagScript.type = 'text/javascript';
  //   gtagScript.textContent = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${measurementId}\');`;

  //   //gtagScript.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${measurementId}\');`;
  //   //document.documentElement.firstChild?.appendChild(gtmScript);
  //   //document.documentElement.firstChild?.appendChild(gtagScript);
  //   this.renderer.appendChild(this.document.head, gtmScript);
  //   this.renderer.appendChild(this.document.head, gtagScript);
  // }

  injectGoogleTagManagerConfig(): void {
    if (this.document?.head?.prepend === undefined) return;
    const gtmContainerId = environment.googleTagManager.containerId;
    const script = this.document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmContainerId}');`;
    this.document.head.prepend(script);

    let noscriptElement = this.document.createElement('noscript');
    noscriptElement.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmContainerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    this.document.body.prepend(noscriptElement);
  }

  closeSideNav(): void {
    this.styleService.showFilter.next(false);
    this.ensembleService.showFilter.next(false);
  }

  closeAllSidenavs(): void {
    this.showHeaderNav = false;
    this.showPlpNav = false;
    this.showCustomizeLookNav = false;
  }

  isHeaderFooterHidden(page: string): boolean {
    const isWideOpen = this.authService.isProdModeWideOpen;

    let hideOnPages = ['/V2login', '/logout-proxy', '/prod'];

    if (isWideOpen) {
      hideOnPages = [];
      return false;
    }

    let isHidden = false;

    for (let i = 0; i < hideOnPages.length; i++) {
      if (page.toLowerCase() === hideOnPages[i].toLowerCase()) {
        isHidden = true;
        break;
      }
    }

    return isHidden;
  }

  getCleanUrl(url: string): string {
    const index = url.indexOf('?');
    if (index !== -1) {
      return url.substring(0, index); // Remove everything after the question mark
    }
    return url;
  }

  isFullScreen(page: string): boolean {
    const fullScreenPages = environment.forceFullScreenOnPages;

    if (fullScreenPages.length === 0) {
      return false;
    }

    const cleanUrl = this.getCleanUrl(page);

    console.log('Full screen?');
    console.log(page);

    let isFullScreen: boolean = false;

    for (let i = 0; i < fullScreenPages.length; i++) {
      // console.log('current page: ' + fullScreenPages[i]);
      if (cleanUrl.toLowerCase() === fullScreenPages[i].toLowerCase()) {
        isFullScreen = true;
        break;
      }

      /// Special Case for '/event/*' pages (make them full screen to start)
      if (cleanUrl.toLowerCase().startsWith('/event/')) {
        isFullScreen = true;
        break;
      }
    }

    return isFullScreen;
  }

  // private setMetaTags() {
  //   this.subscription.add(
  //     this.router.events
  //       .pipe(
  //         filter((event) => event instanceof NavigationEnd),
  //         map(() => {
  //           let route: ActivatedRoute = this.router.routerState.root;
  //           // let routeTitle = '';
  //           let headerMeta: HeaderMetaTags = {};

  //           // console.log('Route Children');
  //           // console.log(route.children);
  //           // console.log('Activated Route');
  //           // console.log(route);

  //           while (route!.firstChild) {
  //             route = route.firstChild;
  //           }

  //           // console.log('route used for metaTags');
  //           // console.log(route);
  //           if (route.snapshot.data['metaTitle']) {
  //             headerMeta.title = route!.snapshot.data['metaTitle'];
  //           }

  //           if (route.snapshot.data['metaDescription']) {
  //             headerMeta.description = route!.snapshot.data['metaDescription'];
  //           }

  //           if (route.snapshot.data['metaKeywords']) {
  //             headerMeta.keywords = route!.snapshot.data['metaKeywords'];
  //           }

  //           const isHomePage =
  //             route.snapshot.url.length === 0 ||
  //             route.snapshot.url[0].path === 'home';

  //           return { headerMeta, isHomePage };
  //         }),
  //       )
  //       .subscribe(({ headerMeta, isHomePage }) => {
  //         // console.log('headerMetaTags');
  //         // console.log(headerMeta);
  //         const baseHeaderTitle: string = environment.baseHeaderTitle;

  //         const title = isHomePage
  //           ? "Jim's Formal Wear | The Premier Tuxedo and Suit Provider" // Home page title
  //           : headerMeta.title
  //             ? headerMeta.title + ' | ' + baseHeaderTitle // if title data is provided
  //             : baseHeaderTitle; // default title

  //         // console.log('Page Title: ' + title);

  //         this.titleService.setTitle(title);

  //         this.metaTagService.updateTag({
  //           name: 'description',
  //           content: headerMeta.description ? headerMeta.description : '',
  //         });

  //         this.metaTagService.updateTag({
  //           name: 'keywords',

  //           content: headerMeta.keywords ? headerMeta.keywords : '',
  //         });
  //         this.canonicalService.setCanonicalURL();
  //       }),
  //   );
  // }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:scroll')
  onScroll() {
    const lastScrollTop = 40;
    const scrollTop =
      window.pageYOffset || this.document.documentElement.scrollTop;

    this.hideHeader = scrollTop > lastScrollTop;
  }
}
