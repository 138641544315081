@if (showSearch) {
  <div class="mobile-search-container">
    <form [formGroup]="formGroup" (ngSubmit)="searchForIt()">
      <mat-form-field appearance="outline" style="width: 100%">
        <span matPrefix> </span>
        <input
          name="search"
          id="search"
          formControlName="search"
          matInput
          type="text"
          matInput
          placeholder="Search"
        />
        <button matSuffix mat-button><mat-icon>search</mat-icon></button>
      </mat-form-field>
    </form>
  </div>
}
