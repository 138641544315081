import {
  AfterContentChecked,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import {
  EnsembleService,
  NavigationService,
} from '@jfw-library/ecommerce/core';
import { Categories, CategoryItem, LinkType } from 'common-types';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.scss'],
})
export class HeaderDesktopComponent implements OnInit, AfterContentChecked {
  @ViewChild('ocMenuTrigger') ocMenuTrigger!: MatMenuTrigger;
  @ViewChild('inspirationMenuTrigger') inspirationMenuTrigger!: MatMenuTrigger;
  @ViewChild('hiwMenuTrigger') hiwMenuTrigger!: MatMenuTrigger;

  @Input() alphaMode!: boolean;
  @Input() isBrowser!: boolean;
  @Input() isLoggedIn!: Signal<boolean | undefined>;
  @Input() user!: Signal<User | null | undefined>;
  @Input() firstLetter!: Signal<string>;

  @Output() openSignIn = new EventEmitter<void>();
  @Output() getStartedEvent = new EventEmitter<void>();

  rentalCategoryItems: CategoryItem[] = [];
  purchaseCategoryItems: CategoryItem[] = [];
  howItWorksNav = this.navigationService.howItWorksNav;
  inspirationNav = this.navigationService.inspirationNav;
  alphaNav = this.navigationService.alphaNav;
  hideGetStarted: boolean = false;

  constructor(
    private navigationService: NavigationService,
    private ensembleService: EnsembleService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const categories = new Categories();
    this.rentalCategoryItems = categories.getRentalItems();
    this.purchaseCategoryItems = categories.getPurchaseItems();
  }

  ngAfterContentChecked(): void {
    this.hideGetStarted = this.router.url.startsWith('/invite');
  }

  get linkType(): typeof LinkType {
    return LinkType;
  }

  onAnon(): void {
    this.openSignIn.next();
  }

  onGetStarted(): void {
    this.getStartedEvent.next();
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  // reloadCurrentRoute(url: string) {
  //   console.log('reloadCurrentRoute to:', url);
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //     this.router.navigate([url]);
  //   });
  // }

  // reloadCurrentRoute(url: string) {
  //   console.log('reloadCurrentRoute to:', url);
  //   const uniqueParam = new Date().getTime(); // Use a unique timestamp as a query parameter
  //   this.router.navigate([url], { queryParams: { reload: uniqueParam } });
  // }

  suitsTuxedosNavigation(url: string) {
    const currentUrl = this.router.url;

    // Check if the user is already on the ensembles page
    if (currentUrl.startsWith('/ensembles/suits-and-tuxedos')) {
      this.ensembleService.triggerEnsembleSearch.next({
        triggerSearch: true,
        resetFilterParams: true,
      });
      return;
    }

    // If not on the ensembles page, navigate to the target URL
    this.router.navigate([url]);
  }

  @HostListener('keydown', ['$event'])
  expandMenu(menuEvent: any, source: string) {
    // if (menuEvent.key === 'Enter' || menuEvent.key === 'ArrowDown') {
    // if (menuEvent.key === 'Tab' || menuEvent.key === 'ArrowDown') {
    if (menuEvent.key === 'Enter') {
      if (source === 'ocMenu') {
        this.ocMenuTrigger.openMenu();
        this.ocMenuTrigger.menu?.focusFirstItem();
        menuEvent.preventDefault();
      }

      if (source === 'hiwMenu') {
        this.hiwMenuTrigger.openMenu();
        this.hiwMenuTrigger.menu?.focusFirstItem();
        menuEvent.preventDefault();
      }

      // if (source === 'inspirationMenu') {
      //   this.inspirationMenuTrigger.openMenu();
      //   this.inspirationMenuTrigger.menu?.focusFirstItem();
      //   menuEvent.preventDefault();
      // }
    }
  }

  @HostListener('keydown', ['$event'])
  closeMenu(menuEvent: any, source: string) {
    if (source === 'ocMenu') {
      this.ocMenuTrigger.closeMenu();
      this.ocMenuTrigger.focus();
      menuEvent.preventDefault();
    }

    if (source === 'hiwMenu') {
      this.hiwMenuTrigger.closeMenu();
      this.ocMenuTrigger.focus();
      menuEvent.preventDefault();
    }

    // if (source === 'inspirationMenu') {
    //   this.inspirationMenuTrigger.closeMenu();
    //   this.hiwMenuTrigger.focus();
    //   menuEvent.preventDefault();
    // }
  }
}
