<div class="main-desk">
  <div class="product-listing-header-mobile">
    <h1 *ngIf="!searchText">{{ categoryItem?.displayName }}</h1>
    <h1 *ngIf="searchText">Search Results for "{{ searchText }}"</h1>
    <div *ngIf="!emptyResults">
      <mat-paginator
        #paginator
        [pageSizeOptions]="[12, 24, 48]"
        [pageSize]="this.pageSize"
        [length]="this.resultSize"
        [pageIndex]="this.pageIndex"
        aria-label="Select page of styles"
        (page)="change($event)"
      ></mat-paginator>
    </div>
  </div>

  <!-- <button
    (click)="showMobileFilters()"
    mat-stroked-button
    color="accent"
    class="mobile"
  >
    <div class="filter-button-container">
      <span>Filter</span>
      <mat-icon>tune</mat-icon>
    </div>
  </button> -->

  <div class="center-desk">
    <div class="filters-desk">
      <!-- <h3>Filter</h3>
      <app-style-filters></app-style-filters> -->
    </div>
    <div class="plp-desk">
      <div class="product-listing-header-desktop">
        <div class="product-header" style="max-width: 1180px">
          <h1 *ngIf="!searchText">{{ categoryItem?.displayName }}</h1>
          <h1 *ngIf="searchText">Search Results for "{{ searchText }}"</h1>
          <div *ngIf="!emptyResults" style="display: flex; align-items: center">
            <mat-paginator
              #paginator
              [pageSizeOptions]="[12, 24, 48]"
              [pageSize]="this.pageSize"
              [length]="this.resultSize"
              [pageIndex]="this.pageIndex"
              aria-label="Select page of styles"
              (page)="change($event)"
            ></mat-paginator>
          </div>
        </div>
      </div>

      <div class="empty-result-container-desktop">
        <span *ngIf="emptyResults"
          >No results found. Please try again with different search terms.</span
        >
      </div>

      <ng-container *ngIf="ensembles || styles; else spinner">
        <div class="empty-result-container-mobile">
          <span *ngIf="emptyResults"
            >No results found. Please try again with different search
            terms.</span
          >
        </div>
        <ng-container *ngIf="ensembles">
          <div
            class="plp-col-desk"
            *ngFor="let ensemble of ensembles; let ensembleIndex = index"
          >
            <div class="plp-item">
              <a
                routerLink="{{ ['/ensemble/' + ensemble.ensembleCode] }}"
                queryParamsHandling="preserve"
              >
                <img
                  class="plp-image-desk"
                  [ngSrc]="getImageFromImageKit(ensemble).src"
                  [alt]="
                    'Click to navigate to the details page for ' +
                    ensemble.title
                  "
                  sizes="(max-width: 299px) 85vw, (max-width: 599px) 28vw, (max-width: 1400px) 20vw, 10vw"
                  [priority]="ensembleIndex < 3 ? true : false"
                  style="aspect-ratio: 2 / 3"
                  fill
                />

                <br />

                <p class="product-title">{{ ensemble.title }}</p></a
              ><br />

              <div class="pricing-container">
                <hr />
                <span class="price-descript">Starting At:</span>
                <div>
                  <span class="price" *ngIf="getEnsembleRentPrice(ensemble) > 0"
                    >Rent: {{ getEnsembleRentPrice(ensemble) | currency }}
                  </span>
                </div>
                <div>
                  <span class="price" *ngIf="getEnsembleBuyPrice(ensemble) > 0"
                    >Buy: {{ getEnsembleBuyPrice(ensemble) | currency }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="styles">
          <div
            class="plp-col-desk"
            *ngFor="let style of styles; let styleIndex = index"
          >
            <div class="plp-item">
              <a
                routerLink="/style/{{ style.styleCode }}"
                queryParamsHandling="preserve"
              >
                <img
                  class="plp-image-desk"
                  [ngSrc]="getImageFromImageKit(style).src"
                  [alt]="
                    'Click to navigate to the details page for ' +
                    style.marketingTitle
                  "
                  sizes="(max-width: 299px) 85vw, (max-width: 599px) 28vw, (max-width: 1400px) 20vw, 10vw"
                  [priority]="styleIndex < 3 ? true : false"
                  style="aspect-ratio: 2 / 3"
                  fill
                />

                <br />
                <p class="product-title">{{ style.marketingTitle }}</p></a
              ><br />

              <div class="pricing-container">
                <hr />
                <span class="price-descript" *ngIf="style.styleType === 'Coat'"
                  >Starting At:</span
                >
                <ng-container *ngIf="hasDisplayPrice(style); else rentBlock">
                  <div>
                    <span class="price">
                      {{ displayRentText(style) }}
                    </span>
                  </div>
                </ng-container>
                <ng-template #rentBlock>
                  <div>
                    <span class="price" *ngIf="getStyleRentPrice(style) > 0"
                      >Rent: {{ getStyleRentPrice(style) | currency }}
                    </span>
                  </div>
                </ng-template>
                <ng-container *ngIf="hasDisplayPrice(style); else buyBlock">
                  <div>
                    <span class="price" *ngIf="isPurchaseOnly(style)">
                      AVAILABLE TO BUY
                    </span>
                  </div>
                </ng-container>
                <ng-template #buyBlock>
                  <div>
                    <span class="price" *ngIf="getStyleBuyPrice(style) > 0"
                      >Buy: {{ getStyleBuyPrice(style) | currency }}</span
                    >
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- *ngFor="let ensemble of ensembles -->
      <ng-template #spinner>
        <mat-spinner></mat-spinner>
      </ng-template>
    </div>
  </div>
  <div *ngIf="!emptyResults" style="margin-left: 5.5vw; max-width: 1180px">
    <mat-paginator
      #paginator
      [pageSizeOptions]="[12, 24, 48]"
      [pageSize]="this.pageSize"
      [length]="this.resultSize"
      [pageIndex]="this.pageIndex"
      aria-label="Select page of styles"
      (page)="change($event)"
    ></mat-paginator>
  </div>
</div>
