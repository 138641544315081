import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Params,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SearchService } from '@jfw-library/ecommerce/core';
import { getEnsembleDesigner } from 'business-logic';
import { EcomEnsemble, SearchCriteria } from 'common-types';
import { map, tap } from 'rxjs';
import { SEOService } from '../../services/seo/seo-service';
type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};
interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class EnsembleResolver implements Resolve {
  private searchService = inject(SearchService);
  private seoService = inject(SEOService);
  private router = inject(Router);
  constructor() {}

  private getFilteredDescriptionForMeta(
    description: string | undefined,
  ): string {
    if (description) {
      //let filteredDescription = description.replace(/[#-]/g, '');
      let descriptionWithoutLineBreaks = description.replace(/\n|\r/g, '');
      let descriptionWithoutHashes = descriptionWithoutLineBreaks.replace(
        /[#-]/g,
        '',
      );
      return descriptionWithoutHashes.substring(0, 160);
    }
    return '';
  }

  private getSearchCriteria(params: Params): Array<SearchCriteria> {
    const { ensembleCode, ensembleGroupCode } = params;
    let searchCriteria: Array<SearchCriteria> = [];
    let ensembleGroupCodeSearch = {
      index: 'ecom-ensembles',
      attribute: 'ensembleGroupCode',
      values: [ensembleCode],
    };
    searchCriteria.push(ensembleGroupCodeSearch);
    return searchCriteria;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let ensembleCodeString: string = state.url;
    let ensembleCodeArray = ensembleCodeString.split('-');
    let setMetaTags = true;
    const updatedParams = {
      ensembleCode: ensembleCodeArray[ensembleCodeArray.length - 1],
    };
    // This if statement is to prevent the excess api call from products search page
    if (
      ensembleCodeArray[ensembleCodeArray.length - 1].includes('searchText')
    ) {
      ensembleCodeArray = ensembleCodeString.split('/');
      updatedParams.ensembleCode =
        ensembleCodeArray[ensembleCodeArray.length - 1].split('?')[0];
      setMetaTags = false;
    } else if (ensembleCodeString.includes('-')) {
      // Entered from PLP
      updatedParams.ensembleCode =
        ensembleCodeArray[ensembleCodeArray.length - 1];
      setMetaTags = true;
    } else {
      // Entered from color chips?
      ensembleCodeArray = ensembleCodeString.split('/');
      updatedParams.ensembleCode =
        ensembleCodeArray[ensembleCodeArray.length - 1];
      setMetaTags = false;
    }
    const ensembles = this.searchService
      .ensemble(this.getSearchCriteria(updatedParams))
      .pipe(
        map((elasticEnsembles: any) => {
          const ensembles: Array<EcomEnsemble> = [];
          if (elasticEnsembles.hits.hits.length > 0) {
            elasticEnsembles.hits.hits.forEach((style: any) => {
              ensembles.push(style._source as EcomEnsemble);
            });
          }
          return ensembles;
        }),
        tap((ensembles) => {
          if (ensembles && ensembles.length) {
            const ensemble = ensembles[0];
            const title = getEnsembleDesigner(ensemble) + ' ' + ensemble.title;
            let metaDescription = ensemble.metaDescription;
            if (
              metaDescription === undefined ||
              metaDescription === null ||
              metaDescription === ''
            ) {
              metaDescription = this.getFilteredDescriptionForMeta(
                ensemble.description,
              );
            }

            const keywords = ensemble.keywords
              ? ensemble.keywords.join(',')
              : undefined;

            const metaInfo: MetaInfo = {
              metaDescription,
              metaTitle: title,
              noIndex: false,
              metaKeywords: keywords,
            };

            // Check if it is a parameterized route (we do not index paramterized routes)
            const { queryParams } = route;
            const isParameterizedRoute = Object.keys(queryParams).length > 0;

            if (isParameterizedRoute) {
              this.seoService.setNoIndexTag(true);
              /**
               * If noIndex is ever true, canonical should be removed
               * Default remove canonical.
               *  */
              this.seoService.setCanonicalTag(state.url, false);
            } else {
              this.seoService.setNoIndexTag(metaInfo?.noIndex ?? undefined);
              this.seoService.setCanonicalTag(state.url, !metaInfo.noIndex);
            }
            this.seoService.setMetaTags(metaInfo);
          } else {
            if (!ensembles[0]) {
              this.router.navigate(['/ensembles/suits-and-tuxedos']);
            }
          }
        }),
      );

    return ensembles;
  }
}
