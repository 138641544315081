<div class="filter-main" [@.disabled]="disableAnimation">
  <div class="sidenav-header">
    <h2>Filter</h2>
    <button id="close-filter-button" mat-icon-button (click)="close()" aria-label="Close Filter">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <app-style-filters *ngIf="showStylesFilters"></app-style-filters>
  <app-ensemble-filters *ngIf="showEnsembleFilters"></app-ensemble-filters>
</div>
