import { EcomEnsemble, EnsembleOccasion, WhoIsPictured } from 'common-types';

export const trendingStyles: EcomEnsemble[] = [
  {
    ensembleCode: '350',
    images: [
      {
        src: 'wedding-suit-medium-grey-performance-stretch-michael-kors-350-1.jpg',
        alt: 'Man wearing Michael Kors Medium Grey suit and matching vest and bow tie standing outside in front of a trellis',
      },
    ],
    realPeople: false,
    keywords: ['gray', 'suit'],
    webReleaseDate: 1688965200000,
    description:
      "### FEATURES:\n- Available to Rent or Buy\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 29 1/2\" length. \n- Shown with ultra slim fit pants with belt loops and buttons to accommodate suspenders.\n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-74\n- Boys' Coat Sizes: 3-18\n- 350 pant sizes: 18-71\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
    ensembleGroupCode: '350',
    categoryRank: 3,
    dealerUse: true,
    companionEnsembleCode: '350-p1',
    displayOnPLP: true,
    groupRank: 1,
    platformEnsembleFirestoreDocumentId: 'pYro9nqBcEwNlYuldJGe',
    price: 190,
    companionEnsemblePrice: 479,
    id: '',
    categories: ['SuitsAndTuxedos'],
    ensembleGroup: 'Rental',
    webDiscontinuedDate: 0,
    title: 'Medium Grey Performance Wedding Suit',
    styleCodes: [
      'RSR96W',
      'RTIBNST',
      'RJWBL',
      'RSOCOP',
      'PHSXPDG',
      'RCT350',
      'RPT350',
      'RVS350',
      'PPKPNST',
    ],
    styles: [],
  },
  {
    ensembleCode: '202',
    occasion: EnsembleOccasion.Wedding,
    images: [
      {
        src: 'wedding-suit-sand-allure-men-brunswick-202-1.jpg',
        alt: 'Newly wed couple standing outside wearing a Wedding Suit Sand Allure Men Brunswick with Matching Fullback Vest',
      },
    ],
    realPeople: false,
    keywords: ['tan', 'khaki', 'stretch'],
    webReleaseDate: 1688101200000,
    description:
      "### FEATURES:\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 30\" length\n- Shown with ultra slim fit pants with belt loops and buttons to accommodate suspenders\n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-66\n- Boys' Coat Sizes: 3-18\n- 202 pant sizes: 18-65\n\n### FABRIC:\n60/40 Super 130's Wool Blend\n\n### FIT:\nUltra Slim Fit",
    ensembleGroupCode: '202',
    title: 'Sand Brunswick Suit',
    categoryRank: 15,
    whoIsPictured: WhoIsPictured.Couple,
    dealerUse: true,
    displayOnPLP: true,
    groupRank: 1,
    platformEnsembleFirestoreDocumentId: '08RBVCNi1Owwl3j7LZ8b',
    price: 190,
    id: '',
    categories: ['SuitsAndTuxedos'],
    ensembleGroup: 'Rental',
    webDiscontinuedDate: 0,
    styleCodes: [
      'RPT202',
      'PMSBLT',
      'PPKPFWI',
      'RSR96W',
      'RJWBL',
      'RCT202',
      'RTIWFWI',
      'RSOCOP',
      'RVS202',
    ],
    styles: [],
  },
  {
    ensembleCode: '421',
    occasion: EnsembleOccasion.Wedding,
    images: [
      {
        src: 'wedding-suit-black-performance-stretch-michael-kors-421.jpg',
        alt: 'man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie',
      },
    ],
    realPeople: false,
    keywords: ['stretch', 'ensemble', 'coat', 'pant', 'suit'],
    webReleaseDate: 1688101200000,
    description:
      "### FEATURES:\n- Available to Rent or Buy\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 29 1/2\" length \n- Shown with ultra slim fit pants with belt loops and buttons to accommodate suspenders\n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-74\n- Boys' Coat Sizes: 3-18\n- 921 pant sizes: 18-71\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
    ensembleGroupCode: '421',
    title: 'Black Performance Wedding Suit',
    categoryRank: 2,
    whoIsPictured: WhoIsPictured.Individual,
    dealerUse: true,
    companionEnsembleCode: '421-p1',
    displayOnPLP: true,
    groupRank: 1,
    platformEnsembleFirestoreDocumentId: 'luVOxbI9rU1QPDwpfhfY',
    price: 190,
    companionEnsemblePrice: 479,
    id: '',
    categories: ['SuitsAndTuxedos'],
    ensembleGroup: 'Rental',
    webDiscontinuedDate: 0,
    styleCodes: [
      'RSR96W',
      'RSOBXS',
      'RJWBL',
      'PMSBLT',
      'PPKPWRW',
      'RCT421',
      'RVS921',
      'RPT921',
      'RTIWXRW',
    ],
    styles: [],
  },
  {
    ensembleCode: '302',
    occasion: EnsembleOccasion.Wedding,
    images: [
      {
        src: 'wedding-suit-blue-performance-stretch-michael-kors-302-6.jpg',
        alt: 'man in Michael Kors Blue Performance suit and Foundations Blue vest and tie standing in front of a staircase',
      },
    ],
    realPeople: false,
    keywords: ['stretch'],
    webReleaseDate: 1688101200000,
    description:
      "### FEATURES:\n- Available to Rent or Buy\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 30 1/2\" length. \n- Shown with ultra slim fit pants with belt loops and buttons to accommodate suspenders.\n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-74\n- Boys' Coat Sizes: 3-18\n- 302 pant sizes: 18-71\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
    ensembleGroupCode: '302',
    title: 'Blue Performance Wedding Suit',
    categoryRank: 5,
    whoIsPictured: WhoIsPictured.Individual,
    dealerUse: true,
    companionEnsembleCode: '302-P1',
    displayOnPLP: true,
    groupRank: 1,
    platformEnsembleFirestoreDocumentId: 'd2czVHhbafHpYhQOY6Se',
    price: 190,
    companionEnsemblePrice: 479,
    styleCodes: [
      'PPKPNMB',
      'RPT302',
      'PMSBLT',
      'RCT302',
      'RJWBL',
      'RSR96W',
      'RVSFNMB',
      'RTIWNMB',
      'RSOCOP',
    ],
    styles: [],
    id: '',
    categories: ['SuitsAndTuxedos'],
    ensembleGroup: 'Rental',
    webDiscontinuedDate: 0,
  },
  {
    ensembleCode: '192',
    occasion: EnsembleOccasion.Unassigned,
    images: [
      {
        src: 'prom-tuxedo-granite-paisley-mark-of-distinction-aries-192-1.jpg',
        alt: 'Senior at the prom in a Prom Tuxedo Granite Paisley Mark of Distinction Aries',
      },
    ],
    realPeople: false,
    keywords: ['black', 'dinner jacket', 'holiday', 'reception'],
    webReleaseDate: 1695877200000,
    description:
      "### FEATURES:\n- Available to Rent or Buy\n- Black satin shawl lapel, side-vented, 30 1/2\" length\n- Fabric covered buttons\n- Shown with ultra slim fit pants with belt loops and buttons to accommodate suspenders\n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-66 \n- Boys' Coat Sizes: 3-18\n- 921 pant sizes: 18-71\n\n### FABRIC:\n100% Polyester\n\n### FIT:\nSlim Fit",
    ensembleGroupCode: '192',
    title: 'Granite Paisley Aries Tuxedo',
    categoryRank: 21,
    whoIsPictured: WhoIsPictured.Unassigned,
    dealerUse: true,
    companionEnsembleCode: '192-P1',
    displayOnPLP: true,
    groupRank: 1,
    platformEnsembleFirestoreDocumentId: 'uBCPUVzWMr7pRoOdiOuv',
    price: 190,
    companionEnsemblePrice: 349,
    id: '',
    categories: ['SuitsAndTuxedos'],
    ensembleGroup: 'Rental',
    webDiscontinuedDate: 0,
    styleCodes: [
      'RCT192',
      'RPT921',
      'RJWBL',
      'PSUSSBL',
      'RSR96W',
      'PPKPWBL',
      'RTIBSAT',
      'RSOBDS',
      'PHSXBLK',
    ],
    styles: [],
  },
  {
    ensembleCode: '981',
    occasion: EnsembleOccasion.Unassigned,
    images: [
      {
        src: 'wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg',
        alt: 'man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie',
      },
    ],
    realPeople: false,
    keywords: ['stretch'],
    webReleaseDate: 1695877200000,
    description:
      "### FEATURES:\n- Tailored to fit all body types.\n- Grosgrain notch lapel, side-vented, 29 1/2\" length\n- Grosgrain trimmed pockets and covered buttons\n- Shown with ultra slim fit pants with belt loops and buttons to accommodate suspenders\n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-66\n- Boys' Coat Sizes: 3-18\n- 921 pant sizes: 18-71\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
    ensembleGroupCode: '981',
    title: 'Black Force Performance Stretch Tuxedo',
    categoryRank: 6,
    whoIsPictured: WhoIsPictured.Unassigned,
    dealerUse: true,
    displayOnPLP: true,
    groupRank: 1,
    platformEnsembleFirestoreDocumentId: 'oK48rACO7kTTF0bKHq5z',
    price: 190,
    id: '',
    categories: ['SuitsAndTuxedos'],
    ensembleGroup: 'Rental',
    webDiscontinuedDate: 0,
    styleCodes: [
      'RVS921',
      'RPT921',
      'PMSBLT',
      'RCT981',
      'RJWBL',
      'PPKPWGO',
      'RSOBXS',
      'RTIWXGO',
    ],
    styles: [],
  },
  {
    ensembleCode: '262',
    images: [
      {
        src: 'wedding-suit-heather-grey-allure-men-clayton-262-1.jpg',
        alt: 'Wedding Suit Heather Grey Allure Men Clayton with Matching Fullback Vest and Navy Bow Tie',
      },
    ],
    realPeople: false,
    keywords: ['gray', 'heather gray'],
    webReleaseDate: 1688619600000,
    description:
      "### FEATURES: \n- Available to Rent or Buy\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 29 1/8\" length\n- Shown with ultra slim fit pants with belt loops and buttons to accommodate suspenders\n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-74\n- Boys' Coat Sizes: 3-18\n- 262 pant sizes: 18-71\n\n### FABRIC:\nSuper 130's Wool\n\n### FIT:\nUltra Slim Fit",
    ensembleGroupCode: '262',
    title: 'Heather Grey Clayton Suit',
    categoryRank: 16,
    dealerUse: true,
    companionEnsembleCode: '262-P1',
    displayOnPLP: true,
    groupRank: 1,
    platformEnsembleFirestoreDocumentId: '8TKpQZ2Sqs5gJChQenLS',
    price: 190,
    companionEnsemblePrice: 479,
    id: '',
    categories: ['SuitsAndTuxedos'],
    ensembleGroup: 'Rental',
    webDiscontinuedDate: 0,
    styleCodes: [
      'RPT262',
      'RVS262',
      'RJWBL',
      'PPKPWNA',
      'RSOCXS',
      'PHSXPNA',
      'RCT262',
      'RTIBXNA',
    ],
    styles: [],
  },
  {
    ensembleCode: '751',
    occasion: EnsembleOccasion.Unassigned,
    images: [
      {
        src: 'wedding-tuxedo-white-ike-behar-waverly-751-1.jpg',
        alt: 'Wedding Tuxedo White Ike Behar Waverly with Black Expressions Fullback Vest and Bow Tie',
      },
    ],
    realPeople: false,
    keywords: ['dinner jacket', 'cruise', 'fashion'],
    webReleaseDate: 1695877200000,
    description:
      "### FEATURES:\n- Tailored to fit all body types\n- Self shawl lapel edged in black satin, center-vented, 30\" length\n- Shown with black ultra slim fit pants with no stripe\n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-60\n- Boys' Coat Sizes: 3-18\n- 991 pant sizes: 18-59\n\n### FABRIC:\n100% Polyester\n\n### FIT:\nUltra Slim Fit",
    ensembleGroupCode: '751',
    title: 'White Waverly Tuxedo',
    categoryRank: 31,
    whoIsPictured: WhoIsPictured.Unassigned,
    dealerUse: true,
    displayOnPLP: true,
    groupRank: 1,
    platformEnsembleFirestoreDocumentId: 'MuX7t6FOGSX5ok1ONkVR',
    price: 180,
    id: '',
    categories: ['SuitsAndTuxedos'],
    ensembleGroup: 'Rental',
    webDiscontinuedDate: 0,
    styleCodes: [
      'RCT751',
      'PTIBXBL',
      'RJWBL',
      'RVSEXBL',
      'PPKPWBL',
      'RSOBDS',
      'RPT991',
      'PHSXBLK',
    ],
    styles: [],
  },
];
