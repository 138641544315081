import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  EnsembleService,
  NavigationService,
  ScrollService,
  SearchService,
} from '@jfw-library/ecommerce/core';
import {
  getEnsembleBuyPrice,
  getEnsembleRentPrice,
  getFilterCategoriesByEnsemble,
  getImage,
  getImageKitImage,
  getOriginalImage,
  setColorClass,
} from 'business-logic';
import {
  Categories,
  CategoryItem,
  EcomEnsemble,
  EcomEnsemblePLP,
  FilterCategory,
  FilterOption,
  Image,
  PaginatorOptions,
  SearchCriteria,
} from 'common-types';
import { Observable, Subscription, firstValueFrom, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';

export interface FilterParam {
  key: string;
  value: string;
}

/// Update 1, commit 1
/// Update 2, commit 3

@Component({
  selector: 'app-ensemble-listing-page',
  templateUrl: './ensemble.component.html',
  styleUrls: ['./ensemble.component.scss'],
})
export class EnsembleListingPageComponent implements OnInit, OnDestroy {
  ensemblesFromAsync: EcomEnsemblePLP[] = [];
  ensemblesFromAsync$: Observable<EcomEnsemblePLP[]> | undefined = undefined;
  // isLoading$ = this.loadingIndicatorService.isLoading$;

  ensembles$: Observable<EcomEnsemble[]> | undefined = undefined;
  subscription = new Subscription();
  categories = new Categories();
  categoryItem: CategoryItem | undefined;
  searchText: string | undefined;
  filterCategories: FilterCategory[] | undefined;
  displayItemsOnPage: boolean = true;
  emptyResults = false;

  resultSize = 0;
  pageSize = 24;
  pageIndex = 0;

  filterParams: FilterParam[] = [];

  loadingSearch: boolean = false;
  loadingComplete: boolean = false;
  eventId: string | undefined;

  imageLoaded: boolean[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    // private loadingIndicatorService: LoadingIndicatorService,
    private ensembleService: EnsembleService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private search: SearchService,
    private titleService: Title,
    private metaTagService: Meta,
    private scrollService: ScrollService,
    private location: Location,
    private router: Router,
    private renderer: Renderer2,
  ) {}

  async ngOnInit() {
    /// Create triggerEnsembleSearch subscription
    /// -- This subscription listens for a triggerEnsembleSearch event and calls searchEnsemblesAsync

    this.subscription.add(
      this.ensembleService.triggerEnsembleSearch.subscribe(async (trigger) => {
        if (trigger.triggerSearch) {
          if (
            trigger.resetFilterParams !== undefined &&
            trigger.resetFilterParams
          ) {
            this.filterCategories?.forEach((category) => {
              category.options?.forEach((option) => {
                option.selected = false;
              });
            });
          }
          // console.log('triggerEnsembleSearch: ', trigger);
          // console.log('TRIGGER > FILTERCATEGORIES', this.filterCategories);
          await this.searchEnsemblesAsync();

          if (this.filterCategories) {
            const filterParams = this.getParamsFromFilterCategories(
              this.filterCategories,
            );

            console.log('TRIGGER ENSEMBLE SEARCH > filterParams', filterParams);

            this.updateBrowserUrlWithParams(filterParams, ['event']);
          } else {
            this.updateBrowserUrlWithParams({});
          }

          this.updateRobotsIndexMetaTag();
        }
      }),
    );

    /// Get initial category and query params
    // const [params, queryParams] = await firstValueFrom(
    //   combineLatest([this.route.params, this.route.queryParams]),
    // );

    // console.log('params', params);
    // /// Set categoryItem
    // this.categoryItem = this.categories.byRoute(params.category);

    // /// Load ensembles
    // await this.loadEnsembles(queryParams);

    /// Leaving this here for now, just in case. This was creating a subcription instead.
    this.subscription.add(
      this.route.params.subscribe(async (params) => {
        if (params.category) {
          this.categoryItem = this.categories.byRoute(params.category);

          const queryParams = this.getQueryStringParameters(this.route);

          console.log('queryParams', queryParams);

          // await this.setFilterCategoriesFromQueryParams(queryParams);

          //console.log('queryParams', queryParams);

          await this.loadEnsembles(queryParams);

          this.updateRobotsIndexMetaTag();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onImageLoad(index: number) {
    this.imageLoaded[index] = true;
    console.log('image loaded', index);
  }

  calculateMinHeight(index: number): number {
    const aspectRatio = 2 / 3;
    const elements = this.renderer
      .selectRootElement('body', true)
      .querySelectorAll('.plp-image-desk');
    const imageElement = elements[index] as HTMLElement;
    const width = imageElement ? imageElement.clientWidth : 0;
    // console.log('imageELement', imageElement);
    console.log('width', width);
    return width * aspectRatio;
  }

  // calculateMinHeight(imageElement: HTMLElement): number {
  //   const aspectRatio = 2 / 3;
  //   const width = imageElement ? imageElement.clientWidth : 0;
  //   console.log('imageElement', imageElement);
  //   console.log('width', width);
  //   return width * aspectRatio;
  // }

  clearFilters() {
    this.ensembleService.triggerEnsembleSearch.next({
      triggerSearch: true,
      resetFilterParams: true,
    });
  }

  filterCategoriesSelected() {
    if (this.filterCategories === undefined) {
      return false;
    }

    let selected = false;

    this.filterCategories.forEach((category) => {
      category.options?.forEach((option) => {
        if (option.selected) {
          selected = true;
        }
      });
    });

    return selected;
  }

  getQueryStringParameters(activatedRoute: ActivatedRoute): {
    [key: string]: string;
  } {
    const queryParams = activatedRoute.snapshot.queryParams;

    // if (!queryParams || Object.keys(queryParams).length === 0) {
    //   console.log('QUERY PARAMS ARE UNDEFINED, NULL, OR EMPTY');
    //   this.metaTagService.removeTag('name="robots"');
    // } else {
    //   console.log('QUERY PARAMS', queryParams);
    //   this.metaTagService.addTags([{ name: 'robots', content: 'noindex' }]);
    // }

    return queryParams;
  }

  updateRobotsIndexMetaTag() {
    // if (!environment.updateRobotsNoIndexForFilteredListings) {
    //   return;
    // }
    // const queryParams = this.route.snapshot.queryParams;
    // if (!queryParams || Object.keys(queryParams).length === 0) {
    //   console.log('QUERY PARAMS ARE UNDEFINED, NULL, OR EMPTY');
    //   this.metaTagService.removeTag('name="robots"');
    // } else {
    //   console.log('QUERY PARAMS', queryParams);
    //   this.metaTagService.addTags([{ name: 'robots', content: 'noindex' }]);
    // }
  }

  async loadEnsembles(queryParams: Params = {}) {
    if (this.categoryItem?.category) {
      let categories = getFilterCategoriesByEnsemble();

      // if (!skipInitialSearch) {
      //   await this.searchEnsemblesAsync();
      // }

      this.filterCategories = await firstValueFrom(
        this.search.getDistinctFiltersByCategories(
          'ecom-ensembles',
          this.categoryItem.category,
          categories,
        ),
      );

      this.setFilterCategoriesFromQueryParams(queryParams);

      this.ensembleService.filterCategories.next(this.filterCategories);

      this.ensembleService.triggerEnsembleSearch.next({ triggerSearch: true });

      // if (this.categoryItem) {
      // this.setSearchCriteriaFromUrlParams();
      // console.log('filterCategories', this.filterCategories);
      // this.subscription.add(
      //   this.ensembleService.searchEnsembles.subscribe({
      //     next: () => this.searchEnsembles(),
      //   })
      // );
      // this.searchEnsembles();
      //this.updateFilterCategories(searchCriteriaArr);
      // }
    }

    return;
  }

  async setFilterCategoriesFromQueryParams(queryParams: Params) {
    // console.log('queryParams', queryParams);

    if (this.filterCategories !== undefined) {
      for (const key in queryParams) {
        const queryKey: string = key.toLowerCase();
        const queryFullValue: string = queryParams[key].toLowerCase();

        const queryValues: string[] = queryFullValue.split('|');

        this.filterCategories?.forEach((category) => {
          if (category.attribute.toLowerCase() === queryKey) {
            queryValues.forEach((queryValue) => {
              category.options?.forEach((option) => {
                if (option.value.toLowerCase() === queryValue) {
                  option.selected = true;
                }
              });
            });
          }
        });
      }

      this.ensembleService.filterCategories.next(this.filterCategories);

      return;

      // console.log('filterCategories', this.filterCategories);

      // this.ensembleService.triggerEnsembleSearch.next(true);
    }

    //await this.searchEnsemblesAsync();
  }

  async searchEnsemblesAsync(paginatorOptions?: PaginatorOptions) {
    this.loadingSearch = true;

    // this.setLoadingIndicatorTrue();

    // this.loadingComplete = false;

    // this.loadingIndicatorService.showLoader();

    const ensemblesSearchResults = await firstValueFrom(
      this.search.ensemblePlp(this.getSearchCriteria(), paginatorOptions),
    );

    this.loadingComplete = true;

    let ensembles: Array<EcomEnsemblePLP> = [];

    // const recastedElasticEnsembleType: any = ensemblesSearchResults.hits.total;

    //const resultsCount = recastedElasticEnsembleType.value;

    const resultsCount = ensemblesSearchResults.totalHits;

    // console.log('resultsCount', resultsCount);

    if (resultsCount > 0) {
      // elasticEnsembles.hits.hits.forEach((ensemble) => {
      //   ensembles.push(ensemble._source as EcomEnsemble);
      // });
      ensembles = ensemblesSearchResults.ensembles ?? [];

      this.emptyResults = false;
      this.resultSize = resultsCount;
    } else {
      this.emptyResults = true;
    }

    if (!paginatorOptions) {
      this.paginator.firstPage();
    }

    this.ensemblesFromAsync = ensembles;

    this.ensemblesFromAsync$ = of(this.ensemblesFromAsync);

    this.scrollToTop();

    // this.loadingIndicatorService.hideLoader();

    this.loadingSearch = false;

    //this.setLoadingIndicatorFalse();
  }

  // setLoadingIndicatorTrue() {
  //   setTimeout(() => {
  //     if (!this.loadingComplete) {
  //       this.loadingSearch = true;
  //     }
  //   }, 1000);
  // }

  // setLoadingIndicatorFalse() {
  //   setTimeout(() => {
  //     if (this.loadingComplete) {
  //       this.loadingSearch = false;
  //     }
  //   }, 1000);
  // }

  getParamsFromFilterCategories(filterCategories: FilterCategory[]) {
    let params: { [key: string]: string } = {};

    filterCategories.forEach((category) => {
      // category.options?.forEach((option) => {
      const filterOption = this.getParamValueForFilterOption(category.options);

      if (filterOption !== '') {
        params[category.attribute] = filterOption;
      }

      // if (option.selected) {
      //   params[category.attribute] = option.value;
      // }
      // });
    });

    return params;
  }

  getParamValueForFilterOption(options: FilterOption[] | undefined) {
    if (options === undefined) {
      return '';
    }

    // console.log('options', options);

    let paramValue = '';
    let index: number = 0;

    options.forEach((option) => {
      if (option.selected) {
        if (index > 0) {
          paramValue += '|';
        }
        paramValue += option.value;
        index++;
      }
    });

    return paramValue;
  }

  updateBrowserUrlWithParams(
    params: { [key: string]: string },
    preserveKeys: string[] = [],
  ) {
    // Get the current query parameters
    const currentParams = this.route.snapshot.queryParams;

    console.log('updateBrowserUrlWithParams', params);
    console.log('currentParams', currentParams);

    // Merge current query params with new params
    const mergedParams = { ...currentParams, ...params };

    // Remove keys from mergedParams that are not in params and not in preserveKeys
    Object.keys(mergedParams).forEach((key) => {
      if (!(key in params) && !preserveKeys.includes(key)) {
        delete mergedParams[key];
      }
    });

    console.log('cleanedParams', mergedParams);

    // Create a URL tree with the cleaned parameters
    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: mergedParams,
    });

    // Replace the current URL with the updated one
    this.router.navigateByUrl(urlTree);
  }

  // updateBrowserUrlWithParams(params: { [key: string]: string }) {
  //   // Get the current URL tree

  //   console.log('UPDATE BROWSER URL WITH PARAMS', params);
  //   const urlTree = this.router.createUrlTree([], {
  //     relativeTo: this.route,
  //     queryParams: params,
  //     // queryParamsHandling: 'merge', // Merge with existing query params
  //   });

  //   // Replace the current URL with the updated one
  //   this.router.navigateByUrl(urlTree);
  // }

  setTitleMetaTag(categoryItem: CategoryItem) {
    const baseHeaderTitle: string = environment.baseHeaderTitle;

    this.titleService.setTitle(
      baseHeaderTitle + ' - ' + categoryItem.displayName,
    );
  }

  setColorClass(colorFamily: string, checkbox?: MatCheckbox): string {
    return setColorClass(colorFamily, checkbox);
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    console.log('WE ARE HANDLING LISTING-PAGE OBSERVABLE ERROR');
    // console.log(err);
    // return throwError(() => err);
    return new Observable<never>();
  }

  getImage(ensemble: EcomEnsemblePLP): Image {
    let image = getImage(ensemble);
    return image;
  }

  getImageFromImageKit(ensemble: EcomEnsemblePLP): Image {
    if (environment.imageKitPLPEnabled) {
      return getImageKitImage(ensemble);
    } else {
      return getImage(ensemble);
    }
  }

  getOriginalImage(ensemble: EcomEnsemble): Image {
    console.log(ensemble.images);
    let image = getOriginalImage(ensemble);
    console.log('original image:');
    console.log(image);
    return image;
  }

  showMobileFilters(): void {
    console.log('show mobile filters');
    this.ensembleService.showFilter.next(true);
    this.navigationService.showPlpNav.next(true);
  }

  getSearchCriteria(): Array<SearchCriteria> {
    let searchCriteria: Array<SearchCriteria> = [];
    if (this.categoryItem?.styleType !== undefined) {
      searchCriteria.push({
        attribute: 'styleType',
        values: [this.categoryItem.styleType],
      });
    }

    this.filterCategories?.forEach((category: FilterCategory) => {
      let criteria: SearchCriteria = {
        attribute: category.attribute,
        values: [],
      };
      let customCriteria: SearchCriteria = {
        attribute: '',
        values: [],
      };
      category.options?.forEach((option: any) => {
        // Rent and Purchase Check
        if (
          // category.attribute === 'ensembleGroup' &&
          option.value === 'Purchase' &&
          option.selected === true
        ) {
          customCriteria.attribute = 'hasPurchaseOption';
          customCriteria.values.push(true);
        }
        if (
          // category.attribute === 'ensembleGroup' &&
          option.selected === true &&
          option.value === 'Rental'
        ) {
          customCriteria.attribute = 'hasPurchaseOption';
          customCriteria.values.push(false);
        }
        // Rent and Purchase Check
        if (option.selected === true && option.value !== 'Purchase') {
          criteria.values.push(option.value);
        }
      });
      if (criteria.values.length > 0) {
        searchCriteria.push(criteria as SearchCriteria);
      }
      if (customCriteria.values.length > 0) {
        searchCriteria.push(customCriteria as SearchCriteria);
      }
    });

    return searchCriteria;
  }

  getRentPrice(ensemble: EcomEnsemblePLP): number {
    return getEnsembleRentPrice(ensemble);
  }

  getBuyPrice(ensemble: EcomEnsemblePLP): number {
    return getEnsembleBuyPrice(ensemble);
  }

  async change(event: PageEvent) {
    let paginatorOptions: PaginatorOptions = {
      previousPageIndex: event.previousPageIndex ?? undefined,
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
      length: event.length,
    };

    console.log('CHANGE PAGE EVENT');

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    await this.searchEnsemblesAsync(paginatorOptions);

    this.scrollToTop();
  }

  getEnsembleTitleAndCode(ensemble: EcomEnsemblePLP) {
    // Replace spaces with hyphens and make the string URL-safe
    const title = encodeURIComponent(
      ensemble.title.toLowerCase().replace(/\s+/g, '-'),
    );
    const titleAndCode = `/ensemble/${title}-${ensemble.ensembleCode}`;

    // if the current URL contains query params, remove them

    return titleAndCode;
    // return url.pathname;
  }

  private scrollToTop(): void {
    this.scrollService.scrollToAnchor('listing-anchor', [0, 5000]);
  }
}
