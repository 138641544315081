import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { StyleService } from '@jfw-library/ecommerce/core';
import { EcomStyle } from 'common-types';
import { catchError, Observable, tap } from 'rxjs';
import { SEOService } from '../../services/seo/seo-service';
type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};
interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class StyleResolver implements Resolve {
  private styleService = inject(StyleService);
  private seoService = inject(SEOService);
  private router = inject(Router);
  style: EcomStyle | undefined = undefined;
  constructor() {}
  private getFilteredDescriptionForMeta(
    description: string | undefined,
  ): string {
    if (description) {
      let descriptionWithoutLineBreaks = description.replace(/\n|\r/g, '');
      let descriptionWithoutHashes = descriptionWithoutLineBreaks.replace(
        /[#-]/g,
        '',
      );
      return descriptionWithoutHashes.substring(0, 160);
    }
    return '';
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let styleCodeString: string = state.url;
    let styleCodeArray = styleCodeString.split('-');
    let styleCode = '';
    let setMetaTags = true;
    // This if statement is to know that we came from products search page skip the api call below.
    if (styleCodeArray[styleCodeArray.length - 1].includes('searchText')) {
      styleCodeArray = styleCodeString.split('/');
      styleCode = styleCodeArray[styleCodeArray.length - 1].split('?')[0];
      setMetaTags = false;
    } else if (styleCodeString.includes('-')) {
      // Entered from PLP
      styleCode = styleCodeArray[styleCodeArray.length - 1];
      setMetaTags = true;
    } else {
      // This is entered from colorchips in PDP
      styleCodeArray = styleCodeString.split('/');
      styleCode = styleCodeArray[styleCodeArray.length - 1];
      setMetaTags = false;
    }
    const style = this.styleService
      .getStyleByStyleCode(styleCode)
      .pipe(
        tap((style) => {
          if (setMetaTags) {
            const metaData: MetaInfo = {
              metaDescription: undefined,
              metaTitle: undefined,
              noIndex: true,
              metaKeywords: undefined,
            };
            if (style) {
              this.style = style;
              const title = style?.marketingTitle;
              let metaDescription = style.metaDescription;
              if (
                metaDescription === undefined ||
                metaDescription === null ||
                metaDescription === ''
              ) {
                metaDescription = this.getFilteredDescriptionForMeta(
                  style.marketingDescription,
                );
              }

              const keywords = style.keywords
                ? style.keywords.join(',')
                : undefined;

              metaData.metaDescription = metaDescription;
              metaData.metaTitle = title;
              metaData.noIndex = false;
              metaData.metaKeywords = keywords;
            }
            const { queryParams } = route;
            const isParameterizedRoute = Object.keys(queryParams).length > 0;

            if (isParameterizedRoute) {
              this.seoService.setNoIndexTag(true);
              /**
               * If noIndex is ever true, canonical should be removed
               * Default remove canonical.
               *  */
              this.seoService.setCanonicalTag(state.url, false);
            } else {
              this.seoService.setNoIndexTag(metaData?.noIndex ?? undefined);
              this.seoService.setCanonicalTag(state.url, !metaData.noIndex);
            }
            this.seoService.setMetaTags(metaData);
          }
        }),
      )
      .pipe(
        catchError((error: HttpErrorResponse): Observable<never> => {
          this.router.navigate(['/home']);
          return new Observable<never>();
        }),
      );

    return style;
  }
}
