import { mapToCanActivate, Routes } from '@angular/router';
import { AnonTokenGuard } from '@jfw-library/ecommerce/core';
import { ProdLoginComponent } from './core/components/authentication/login/prod-login/prod-login.component';
import { SignInV2Component } from './core/components/authentication/login/sign-in-v2/sign-in-v2.component';
import { HomePageComponent } from './core/components/home-page/home-page.component';
import { EnsembleListingPageComponent } from './core/components/listing-pages/ensemble/ensemble.component';
import { SearchListingPageComponent } from './core/components/listing-pages/search/search.component';
import { StyleListingPageComponent } from './core/components/listing-pages/style/style.component';
import { verifyLoginUserGuard } from './core/guards/verify-user-login/verify-user-login.guard';
import { EnsembleResolver } from './core/resolvers/ensemble/ensemble.resolver';
import { SEOResolver } from './core/resolvers/seo/seo.resolver';
import { StoreDetailsResolver } from './core/resolvers/store-details/store-details-resolver';
import { StyleCategoryResolver } from './core/resolvers/style/style-category.resolver';
import { StyleResolver } from './core/resolvers/style/style.resolver';
export const routes: Routes = [
  /////////////////////////////////////////////////////////////////////////////////////////////////
  // CORE MODULE
  /////////////////////////////////////////////////////////////////////////////////////////////////
  { path: 'prod', component: ProdLoginComponent },
  { path: 'V2login', component: SignInV2Component },
  {
    path: 'ensembles/:category',
    component: EnsembleListingPageComponent,
    canActivate: mapToCanActivate([AnonTokenGuard]),
    data: {
      seoParams: {
        metaTitle: 'Suit and Tuxedos',
        metaDescription:
          'Discover hundreds of suit and tuxedo combinations for your next event. Rent online or at over 4,500 locations. Find your perfect look with top national brands.',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'ensembles',
    // component: EnsembleListingPageComponent,
    // canActivate: mapToCanActivate([AnonTokenGuard]),
    pathMatch: 'full',
    redirectTo: '/ensembles/suits-and-tuxedos',
    resolve: { SEOResolver },
  },
  /* {
    path: 'ensembles/:category/:colorFamily',
    component: EnsembleListingPageComponent,
    canActivate: mapToCanActivate([AnonTokenGuard]),
    data: {
      metaTitle: 'Ensembles Test',
      metaDescription: 'Ensembles Listings',
      metaKeywords: 'ensembles, tuxedos, formal wear, JFW',
    },
  },
  {
    path: 'ensembles/:category/:key1/:val1',
    component: EnsembleListingPageComponent,
    canActivate: mapToCanActivate([AnonTokenGuard]),
    data: {
      metaTitle: 'Ensembles Test',
      metaDescription: 'Ensembles Listings',
      metaKeywords: 'ensembles, tuxedos, formal wear, JFW',
    },
  },
  {
    path: 'ensembles/:category/:key1/:val1/:key2/:val2',
    component: EnsembleListingPageComponent,
    canActivate: mapToCanActivate([AnonTokenGuard]),
    data: {
      metaTitle: 'Ensembles Test',
      metaDescription: 'Ensembles Listings',
      metaKeywords: 'ensembles, tuxedos, formal wear, JFW',
    },
  },*/
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: mapToCanActivate([AnonTokenGuard]),
    data: {
      seoParams: {
        metaTitle: `Jim's Formal Wear`,
        metaDescription: `The nation’s largest men's tuxedo and suit rental provider, with over 4,500 retailers in all 50 states. Rent or buy the latest styles from top brands today.`,
        metaKeywords: 'tuxedos, formal wear, JFW, Jims',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  /*{
    path: 'home-copy',
    component: HomePageCopyComponent,
    canActivate: mapToCanActivate([AnonTokenGuard]),
    data: {
      metaTitle: 'Home-copy',
      metaDescription: 'Home page description',
      metaKeywords: 'tuxedos, formal wear, JFW, Jims',
    },
  },*/
  {
    path: 'products',
    component: SearchListingPageComponent,
    canActivate: [...mapToCanActivate([AnonTokenGuard])],
    data: {
      seoParams: {
        noIndex: true,
      },
    },
    //
    resolve: { SEOResolver },
  },
  /*{
    path: 'search',
    component: SearchMobileComponent,
    canActivate: mapToCanActivate([AnonTokenGuard]),
    data: {
      seoParams: {
        noIndex: false,
      }
    },
    resolve: { SEOResolver }
  },*/
  {
    path: 'styles/:category',
    component: StyleListingPageComponent,
    canActivate: mapToCanActivate([AnonTokenGuard]),
    data: {
      seoParams: {
        noIndex: false,
      },
    },
    resolve: { StyleCategoryResolver },
  },
  /*{
    path: 'styles',
    component: StyleListingPageComponent,
    canActivate: mapToCanActivate([AnonTokenGuard]),
  },*/

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // LAZY-LOADED COMPONENTS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: 'accessibility',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/accessibility/accessibility.component'
      ).then((c) => c.AccessibilityComponent),
    data: {
      seoParams: {
        metaTitle: "Accessibility | Jim's Formal Wear",
        metaDescription:
          "We're dedicated to an inclusive experience for all. Check our accessibility statement to see how we ensure equal access for everyone.",
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'auth-email-action',
    loadComponent: () =>
      import(
        '@jfw-library/ecommerce/common-components/auth-email-actions'
      ).then((c) => c.AuthEmailActionContainerComponent),
    canActivate: mapToCanActivate([AnonTokenGuard]),
  },
  {
    path: 'become-a-jfw-retailer',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/become-a-jfw-retailer/become-a-jfw-retailer.component'
      ).then((c) => c.BecomeAJfwRetailerComponent),
    data: {
      seoParams: {
        metaTitle: 'Become a Retailer',
        metaDescription:
          'Join our network of retailers and become a part of the JFW family. Explore the benefits and opportunities of becoming an authorized JFW retailer. Apply now!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'become-a-jfw-retailer-thank-you',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/become-a-jfw-retailer-thank-you/become-a-jfw-retailer-thank-you.component'
      ).then((c) => c.BecomeAJfwRetailerThankYouComponent),
    data: {
      seoParams: {
        metaTitle: 'Become a JFW Retailer Thank You',
        metaDescription:
          'Thank you for your interest in becoming a JFW retailer. A member of our sales team will be in touch with you soon.',
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'become-a-referral-partner',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/become-a-referral-partner/become-a-referral-partner.component'
      ).then((c) => c.BecomeAReferralPartnerComponent),
    data: {
      seoParams: {
        metaTitle: 'Become A Referral Partner',
        metaDescription:
          'Unlock new earning opportunities by becoming a referral partner. Join our program and earn rewards for referring customers to our services. Start earning today!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'become-a-referral-partner-thank-you',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/become-a-referral-partner-thank-you/become-a-referral-partner-thank-you.component'
      ).then((c) => c.BecomeAReferralPartnerThankYouComponent),
    data: {
      seoParams: {
        metaTitle: 'Become A JFW Referral Partner Thank You',
        metaDescription:
          'Thank you for your interest in becoming a JFW referral partner. A member of our team will be in touch with you soon.',
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'big-and-tall',
    //canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/big-and-tall/big-and-tall.component'
      ).then((c) => c.BigAndTallComponent),
    data: {
      seoParams: {
        metaTitle: "Big and Tall | Jim's Formal Wear",
        metaDescription:
          'Discover the perfect fit with our big and tall clothing collection. Explore stylish, comfortable options for every occasion. Shop now and embrace your unique style!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'careers',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/careers/careers.component'
      ).then((c) => c.CareersComponent),
    data: {
      seoParams: {
        metaTitle: `Careers`,
        metaDescription: `Build a career with JFW. Explore exciting job opportunities and join our talented team. Discover a fulfilling career path. Apply now and unleash your potential!`,
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'charity-work',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/charity-work/charity-work.component'
      ).then((c) => c.CharityWorkComponent),
    data: {
      seoParams: {
        metaTitle: "Charity Work | Jim's Formal Wear",
        metaDescription:
          'Learn how JFW positively impacts through our charity work. Discover our philanthropic efforts and the causes we support. Join us in making a difference.',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  // disabled for now
  // {
  //   path: 'color-guide',
  //   canActivate: mapToCanActivate([AnonTokenGuard]),
  //   loadComponent: () =>
  //     import(
  //       './standalone/components/content-pages/color-guide/color-guide.component'
  //     ).then((c) => c.ColorGuideComponent),
  // },
  {
    path: 'contact-us',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/contact-us/contact-us.component'
      ).then((c) => c.ContactUsComponent),
    data: {
      seoParams: {
        metaTitle: "Contact Us | Jim's Formal Wear",
        metaDescription:
          "Connect with us easily through our contact page. Reach out for inquiries, feedback, or assistance. We're here to help. Get in touch with us today!",
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'contests',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/contests/contests.component'
      ).then((c) => c.ContestsComponent),
    data: {
      seoParams: {
        metaTitle: 'Contests',
        metaDescription: `Join our exciting contests for a chance to win amazing prizes like free suits and tuxedos. Don't miss out—enter now and seize the opportunity to win big!`,
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'seasonal-giveaways',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/seasonal-giveaways/seasonal-giveaways.component'
      ).then((c) => c.SeasonalGiveawaysComponent),
    data: {
      seoParams: {
        metaTitle: 'Seasonal Giveaways',
        metaDescription:
          'Participate in exciting contests and stand a chance to win amazing prizes like free suits and tuxedos. Join the competition and seize the opportunity to win big. Enter now!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'win-free-wedding',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/win-free-wedding/win-free-wedding.component'
      ).then((c) => c.WinFreeWeddingComponent),
    data: {
      seoParams: {
        metaTitle: 'Win 5 Free Wedding Tuxedos',
        metaDescription:
          'Participate in exciting contests and stand a chance to win amazing prizes like free suits and tuxedos. Join the competition and seize the opportunity to win big. Enter now!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  /*{
    path: 'debug/local-storage',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/debug/local-storage/local-storage.component'
      ).then((c) => c.LocalStorageComponent),
  },*/
  // {
  //   path: 'debug/test-modals',
  //   canActivate: mapToCanActivate([AnonTokenGuard]),
  //   loadComponent: () =>
  //     import(
  //       './standalone/components/debug/test-modals/test-modals.component'
  //     ).then((c) => c.TestModalsComponent),
  // },
  {
    path: 'email-us',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/email-us/email-us.component'
      ).then((c) => c.EmailUsComponent),
    data: {
      seoParams: {
        metaTitle: 'Email Us',
        metaDescription: '',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'faq',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import('./standalone/components/content-pages/faqs/faqs.component').then(
        (c) => c.FaqsComponent,
      ),
    data: {
      seoParams: {
        metaTitle: 'FAQs',
        metaDescription:
          'Find answers to common questions in our comprehensive FAQ page. Get the information you need about our products, services, and policies. Explore now!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'free-swatches',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/free-swatches/free-swatches.component'
      ).then((c) => c.FreeSwatchesComponent),
    data: {
      seoParams: {
        metaTitle: 'Free Swatches',
        metaDescription:
          'Order free swatches to experience our products firsthand. Feel the quality before making your decision. Get yours now and make an informed choice!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'getting-measured',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/getting-measured/getting-measured.component'
      ).then((c) => c.GettingMeasuredComponent),
    data: {
      seoParams: {
        metaTitle: `Getting Measured`,
        metaDescription:
          'Get the perfect fit for your suit with professional measurements. Visit our webpage to learn how to measure accurately for a tailored look. Get measured now!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'direct-delivery',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/direct-delivery/direct-delivery.component'
      ).then((c) => c.DirectDeliveryComponent),
    data: {
      seoParams: {
        metaTitle: `More Convenient Rentals With Direct Delivery`,
        metaDescription:
          'Get your rentals right to your door with our Direct Delivery option. Choose Direct Delivery to ensure a stress-free option for getting your products to your front door and on time.',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'invite/:eventId/:memberEmail',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/invite-landing-page/invite-landing-page.component'
      ).then((c) => c.InviteLandingPageComponent),
  },
  // {
  //   path: 'generate-sitemap',
  //   canActivate: [AnonTokenGuard],
  //   loadComponent: () =>
  //     import(
  //       './standalone/components/generate-sitemap/generate-sitemap.component'
  //     ).then((c) => c.GenerateSiteMapPageComponent),
  // },
  {
    path: 'kids',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import('./standalone/components/content-pages/kids/kids.component').then(
        (c) => c.KidsComponent,
      ),
    data: {
      seoParams: {
        metaTitle: "Kids Rentals | Jim's Formal Wear",
        metaDescription:
          'Dress your young ones in style with our trendy youth clothing sizes. From casual to formal, explore fashionable options for kids and teens. Shop now for the perfect fit!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'measurements-return',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/measurements-return/measurements-return.component'
      ).then((c) => c.MeasurementsReturnComponent),
  },
  {
    path: 'michael-kors-purse-giveaway-rules',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/michael-kors-purse-giveaway-rules/michael-kors-purse-giveaway-rules.component'
      ).then((c) => c.MichaelKorsPurseGiveawayRulesComponent),
    data: {
      seoParams: {
        metaTitle: 'Michael Kors Purse Giveaway Rules',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'our-process',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/our-process/our-process.component'
      ).then((c) => c.OurProcessComponent),
    data: {
      seoParams: {
        metaTitle: 'Our Process',
        metaDescription:
          'Explore our detailed process that ensures top-notch quality and service from start to finish. Discover how we achieve excellence, step by step.',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'out-of-town-groomsmen',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/out-of-town-groomsmen/out-of-town-groomsmen.component'
      ).then((c) => c.OutOfTownGroomsmenComponent),
    data: {
      seoParams: {
        metaTitle: 'An Easy Process For Out of Town Groomsmen',
        metaDescription:
          'Guide for out-of-town groomsmen: Navigate your role with ease. From coordination advice to getting measured, make the most of your groomsman experience. Read more!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'privacy',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/privacy/privacy.component'
      ).then((c) => c.PrivacyComponent),
    data: {
      seoParams: {
        metaTitle: "Privacy Policy | Jim's Formal Wear",
        metaDescription:
          'Our privacy policy ensures your personal information is protected. Learn how we handle data, maintain security, and respect your privacy. Read our policy now.',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  // disabled for now
  // {
  //   path: 'real-people',
  //   canActivate: mapToCanActivate([AnonTokenGuard]),
  //   loadComponent: () =>
  //     import(
  //       './standalone/components/content-pages/real-people/real-people.component'
  //     ).then((c) => c.RealPeopleComponent),
  //   data: {
  //     seoParams: {
  //       noIndex: false,
  //     },
  //   },
  //   resolve: { SEOResolver },
  // },
  {
    path: 'returns-and-exchanges',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/returns-and-exchanges/returns-and-exchanges.component'
      ).then((c) => c.ReturnsAndExchangesComponent),
    data: {
      seoParams: {
        metaTitle: 'Returns and Exchanges',
        metaDescription:
          'Easy and hassle-free returns and exchanges. Learn about our flexible policies and procedures. Customer satisfaction is our top priority. Start your return or exchange process now!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'request-return-label',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/request-return-label/request-return-label.component'
      ).then((c) => c.RequestReturnLabelComponent),
  },
  /*{
    path: 'sitemap',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/site-map/site-map.component'
      ).then((c) => c.SiteMapComponent),
      data: {
        seoParams: {
          noIndex: false
        }
      },
      resolve: { SEOResolver }
  },*/
  {
    path: 'special-offers',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/special-offers/special-offers.component'
      ).then((c) => c.SpecialOffersComponent),
    data: {
      seoParams: {
        metaTitle: 'Special Offers',
        metaDescription:
          "Discover exclusive special offers and promotions. Save on your favorite products and services. Don't miss out—explore these incredible deals today!",
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'store-detail/:storeId',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    // metaTitle and metaDescription are set on the StoreDetailPageComponent
    loadComponent: () =>
      import(
        './standalone/components/store/store-detail-page/store-detail-page.component'
      ).then((c) => c.StoreDetailPageComponent),
    resolve: { store: StoreDetailsResolver },
  },
  {
    path: 'store-detail',
    pathMatch: 'full',
    redirectTo: 'store-locator',
  },
  {
    path: 'store-locator',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    data: {
      seoParams: {
        metaTitle: 'Store Locator',
        metaDescription:
          'Discover our convenient store locator tool to find a location near you. Explore our products and services at a store nearby. Find your nearest store today!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
    loadComponent: () =>
      import(
        './standalone/components/store/store-locator-page/store-locator-page.component'
      ).then((c) => c.StoreLocatorPageComponent),
  },
  {
    path: 'style/:styleCode',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/style-detail-page/style-detail-page.component'
      ).then((c) => c.StyleDetailPageComponent),
    data: {
      noIndex: false,
    },
    resolve: { style: StyleResolver },
  },
  {
    path: 'terms-of-use',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/terms-of-use/terms-of-use.component'
      ).then((c) => c.TermsOfUseComponent),
    data: {
      seoParams: {
        metaTitle: "Terms Of Use | Jim's Formal Wear",
        metaDescription:
          'Explore our terms of use to understand the guidelines governing your interactions with our website. Ensure a transparent experience by reading our policies now.',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'the-jfw-story',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/the-jfw-story/the-jfw-story.component'
      ).then((c) => c.TheJfwStoryComponent),
    data: {
      seoParams: {
        metaTitle: "The JFW Story | Jim's Formal Wear",
        metaDescription:
          'Discover the inspiring JFW story. From humble beginnings to a national brand, learn about our journey, values, and commitment to excellence. Explore our story now!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'ways-to-rent-or-buy',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/ways-to-rent-or-buy/ways-to-rent-or-buy.component'
      ).then((c) => c.WaysToRentOrBuyComponent),
    data: {
      seoParams: {
        metaTitle: 'Ways to Rent or Buy',
        metaDescription:
          'Discover hassle-free options to rent or buy products. Explore our wide selection and flexible choices for convenient shopping. Find the perfect solution today!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'why-choose-jfw',
    canActivate: mapToCanActivate([AnonTokenGuard]),
    loadComponent: () =>
      import(
        './standalone/components/content-pages/why-choose-jfw/why-choose-jfw.component'
      ).then((c) => c.WhyChooseJfwComponent),
    data: {
      seoParams: {
        metaTitle: 'Why Choose JFW',
        metaDescription:
          'Choose JFW for exceptional tuxedo rental. Experience impeccable quality, extensive selection, and outstanding service. Elevate your special occasion. Choose JFW!',
        noIndex: false,
      },
    },
    resolve: { SEOResolver },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // LAZY-LOADED MODULES
  /////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: 'checkout',
    loadChildren: () =>
      import('./modules/checkout/checkout.module').then(
        (m) => m.CheckoutModule,
      ),
  },
  {
    path: 'customize-look',
    loadChildren: () =>
      import('@jfw-library/ecommerce/feature-modules/customize-look').then(
        (m) => m.CustomizeLookModule,
      ),
    data: {
      seoParams: {
        noIndex: true,
      },
    },
    resolve: { SEOResolver },
  },

  {
    path: 'ensemble',
    loadChildren: () =>
      import('./modules/ensemble-detail-page/ensemble-detail-page.module').then(
        (m) => m.EnsembleDetailPageModule,
      ),
    data: {
      noIndex: false,
    },
    resolve: { ensembles: EnsembleResolver },
    // resolve: { SEOResolver },
  },
  {
    path: 'event',
    canActivate: [verifyLoginUserGuard],
    loadChildren: () =>
      import('@jfw-library/ecommerce/feature-modules/event-manager').then(
        (m) => m.EventManagerModule,
      ),
    data: {
      seoParams: {
        noIndex: true,
      },
    },
    resolve: { SEOResolver },
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./modules/my-account/my-account.module').then(
        (m) => m.MyAccountModule,
      ),
  },

  //{ path: 'privacy-policy', redirectTo: 'privacy' },
  // default to /home
  { path: '**', redirectTo: 'home' },
];
