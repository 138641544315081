<div class="parent" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <form [formGroup]="form" (ngSubmit)="onSearch()">
    <mat-form-field [ngClass]="{ expand: expandInput }">
      <button
        aria-label="Search icon to enter search field"
        matPrefix
        mat-icon-button
        (keydown)="onKeydown($event)"
        (click)="onMouseEnter()"
        class="search-button"
      >
        <mat-icon>search</mat-icon>
      </button>
      <label for="search" style="display: none">Search</label>
      <!-- (blur)="onBlur()" -->
      <input
        id="search"
        matInput
        #input="matInput"
        formControlName="search"
        aria-label="Search for products"
        (keydown)="inputKeydown($event)"
      />
      @if (hasValue()) {
        <button matSuffix mat-icon-button type="button" (click)="onClear()">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  </form>
</div>
