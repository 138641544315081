import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { StyleService } from '@jfw-library/ecommerce/core';
import { setColorClass } from 'business-logic';
import { FilterCategory } from 'common-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-style-filters',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss'],
})
export class StyleFiltersComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  filterCategories: Array<FilterCategory> | undefined;
  colorMap: Record<string, string> = {};
  constructor(private styleService: StyleService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.styleService.filterCategories.subscribe({
        next: (filterCategories) => {
          this.filterCategories = filterCategories;
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setColorClass(colorFamily: string, checkbox?: MatCheckbox): string {
    return setColorClass(colorFamily, checkbox);
  }

  rentOrBuyMask(option: string): string {
    let maskedOption = option;
    switch (option) {
      case 'Rental':
        maskedOption = 'Rent';
        break;
      case 'Purchase':
        maskedOption = 'Buy';
        break;
      case 'Rental/Purchase':
        maskedOption = 'Rent/Buy';
        break;
    }
    return maskedOption;
  }

  public optionClicked(
    category: FilterCategory,
    categoryIndex: number,
    optionIndex: number
  ): void {
    // Single Selector logic
    // if (category.select === FilterSelect.Single) {
    //   this.filterCategories![categoryIndex].options?.forEach(
    //     (option, index) => {
    //       if (index !== optionIndex) {
    //         option.selected = false;
    //       }
    //     }
    //   );
    // }
    this.styleService.searchStyles.next();
  }
}
