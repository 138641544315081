<div *ngIf="!isProdModeWideOpen">
  <div *ngIf="currentUser()">Is Authenticated</div>
  <mat-card
    class="login-wrapper"
    appearance="outlined"
    style="max-width: 300px; margin: 0 auto; margin-top: 30px"
    *ngIf="!loadingLogin()"
  >
    <div style="text-align: center">
      <p>
        <strong>Sign In v2.5</strong>
      </p>

      <div *ngIf="verified !== '' || error !== ''">
        <strong style="color: red"
          >There are some issues with your account:</strong
        >
        <div style="text-align: left">
          <ul>
            <li style="margin-bottom: 10px" *ngIf="verified !== ''">
              {{ verified }}
            </li>
            <li style="margin-bottom: 10px" *ngIf="error !== ''">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>

      <button
        mat-flat-button
        color="primary"
        (click)="signInWithGoogle()"
        style="width: 250px"
      >
        <img
          src="assets/images/icons/external-logos/google.png"
          style="width: 24px; height: 24px; margin-right: 10px"
        />Sign in with Google
      </button>
      <!-- <br />
    <br />
   <button
      mat-flat-button
      color="primary"
      (click)="anonIn()"
      style="width: 250px"
    >
      <mat-icon
        style="
          font-size: 30px;
          margin-right: 14px;
          margin-top: -6px;
          margin-left: -3px;
        "
        >account_circle</mat-icon
      >Anonymous Guest
    </button> -->
    </div>
    <div style="margin-top: 20px; margin-bottom: 20px; text-align: center">
      <a
        data-cy="email-login"
        (click)="showHideEmailPasswordForm()"
        style="cursor: pointer"
        >Use Email/Password</a
      >
    </div>

    <app-email-password-form
      *ngIf="displayEmailPasswordForm()"
    ></app-email-password-form>
  </mat-card>
</div>
