<mat-dialog-content>
  <form [formGroup]="signInForm" (ngSubmit)="onSignIn()">
    <mat-form-field
      [ngClass]="
        signInForm.get('email')?.invalid && signInForm.get('email')?.touched
          ? 'error'
          : ''
      "
    >
      <mat-label>Email</mat-label>
      <input data-cy="email" type="email" matInput formControlName="email" />
      <mat-error>
        <span *ngIf="signInForm.get('email')?.errors?.required">
          Email is required.
        </span>
        <span *ngIf="signInForm.get('email')?.errors?.email">
          Email is not valid.
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field
      [ngClass]="
        signInForm.get('password')?.invalid &&
        signInForm.get('password')?.touched
          ? 'error'
          : ''
      "
    >
      <mat-label>Password</mat-label>
      <input
        data-cy="password"
        type="password"
        matInput
        formControlName="password"
      />
      <mat-error>
        <span *ngIf="signInForm.get('password')?.errors?.minlength">
          Password must be at least 8 characters.
        </span>
        <span *ngIf="signInForm.get('password')?.errors?.required">
          Password is required.
        </span>
      </mat-error>
    </mat-form-field>

    <!-- <p *ngIf="this.invalidCredentials" class="invalid">Wrong</p> -->

    <button
      data-cy="sign-in"
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="!signInForm.valid"
    >
      Sign In
    </button>
  </form>
</mat-dialog-content>
