import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  NavigationService,
  ScrollService,
  SearchService,
  StyleService,
} from '@jfw-library/ecommerce/core';
import {
  canOnlyBuyStyle,
  canOnlyRentStyle,
  canRentAndBuyStyle,
  getFilterCategoriesByPlpCategory,
  getImage,
  getImageKitImage,
  getStyleBuyPrice,
  getStyleRentPrice,
  hasDisplayPrice,
} from 'business-logic';
import {
  Categories,
  Category,
  CategoryItem,
  EcomStyle,
  FilterCategory,
  FilterOption,
  FilterSelect,
  Image,
  PaginatorOptions,
  SearchCriteria,
  StyleGroup,
} from 'common-types';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-style-listing-page',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss'],
})
export class StyleListingPageComponent implements OnInit, OnDestroy {
  styles: EcomStyle[] | null = null;
  subscription = new Subscription();
  styleGroup: StyleGroup = StyleGroup.Unassigned;
  categoryItem: CategoryItem | undefined;
  filterCategories: FilterCategory[] = [];
  categories = new Categories();
  resultSize = 0;
  pageSize = 24;
  pageIndex = 0;
  emptyResults = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private styleService: StyleService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private search: SearchService,
    private scrollService: ScrollService,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.params.subscribe(async (params) => {
        if (params.category) {
          console.log('%cPARAMS CHANGED', 'font-weight: bold; color: red;');

          this.categoryItem = this.categories.byRoute(params.category);

          const queryParams = this.route.snapshot.queryParams;

          // this.updateRobotsIndexMetaTag();

          this.paramsChange(params, queryParams).then(() => {
            this.getStyles();
            // this.updateRobotsIndexMetaTag();
          });
        }
      }),
    );

    this.subscription.add(
      this.styleService.searchStyles.subscribe({
        next: () => {
          console.log('searchStyles subscription was triggered');
          this.searchStyles();

          if (this.filterCategories) {
            const filterParams = this.getParamsFromFilterCategories(
              this.filterCategories,
            );
            this.updateBrowserUrlWithParams(filterParams, ['event']);
          } else {
            this.updateBrowserUrlWithParams({});
          }
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  calculateMinHeight(index: number): number {
    const aspectRatio = 2 / 3;
    const elements = this.renderer
      .selectRootElement('body', true)
      .querySelectorAll('.plp-image-desk');
    const imageElement = elements[index] as HTMLElement;
    const width = imageElement ? imageElement.clientWidth : 0;
    // console.log('imageELement', imageElement);
    console.log('width', width);
    return width * aspectRatio;
  }

  // calculateMinHeight(index: number): number {
  //   const aspectRatio = 2 / 3;
  //   const imageElement = this.renderer.selectRootElement(
  //     '.plp-image-desk',
  //     true,
  //   )[index] as HTMLElement;
  //   const width = imageElement ? imageElement.clientWidth : 0;
  //   return width * aspectRatio;
  // }

  filterCategoriesSelected() {
    if (this.filterCategories === undefined) {
      return false;
    }

    let selected = false;

    this.filterCategories.forEach((category) => {
      category.options?.forEach((option) => {
        if (option.selected) {
          selected = true;
        }
      });
    });

    return selected;
  }

  clearFilters() {
    if (this.filterCategories !== undefined) {
      this.filterCategories?.forEach((category) => {
        category.options?.forEach((option) => {
          option.selected = false;
        });
      });

      this.updateBrowserUrlWithParams({}, ['event']);

      this.styleService.filterCategories.next(this.filterCategories);

      this.searchStyles();
    }
  }

  // updateRobotsIndexMetaTag() {
  //   if (!environment.updateRobotsNoIndexForFilteredListings) {
  //     return;
  //   }
  //   const queryParams = this.route.snapshot.queryParams;

  //   if (!queryParams || Object.keys(queryParams).length === 0) {
  //     console.log('QUERY PARAMS ARE UNDEFINED, NULL, OR EMPTY');
  //     this.metaTagService.removeTag('name="robots"');
  //   } else {
  //     console.log('QUERY PARAMS', queryParams);
  //     this.metaTagService.addTags([{ name: 'robots', content: 'noindex' }]);
  //   }
  // }

  private async paramsChange(
    params: Params,
    queryParams: Params,
  ): Promise<void> {
    // console.log('query params', queryParams);

    if (params.category) {
      this.categoryItem = this.categories.byRoute(params.category);
      // if (this.categoryItem) {
      //   this.setMetaTags(this.categoryItem);
      // }
      if (this.categoryItem?.category === 'ShoesAndSocks') {
        // TO BE REMOVED WHEN SHOES AND SOCKS MERGED
        let colorFamily: FilterCategory = {
          attribute: 'colorFamily',
          displayName: 'Color Family',
          options: [],
        };
        let product: FilterCategory = {
          attribute: 'styleType',
          displayName: 'Product',
          options: [],
        };

        let styleGroup: FilterCategory = {
          attribute: 'styleGroup',
          displayName: 'Rent or Buy',
          options: [],
        };

        let styleCategories = getFilterCategoriesByPlpCategory(
          this.categoryItem?.category,
        );

        const distinctShoesFilters = await firstValueFrom(
          this.search
            .getDistinctFiltersByCategories(
              'ecom-styles',
              Category.Shoes,
              styleCategories,
            )
            .pipe(catchError(this.handleError)),
        );

        this.setShoesFilters(
          distinctShoesFilters,
          colorFamily,
          styleGroup,
          product,
        );

        const distinctSocksFilters = await firstValueFrom(
          this.search
            .getDistinctFiltersByCategories(
              'ecom-styles',
              Category.Socks,
              styleCategories,
            )
            .pipe(catchError(this.handleError)),
        );

        this.setSocksFilters(
          distinctSocksFilters,
          colorFamily,
          styleGroup,
          product,
        );

        this.setFilterCategoriesFromQueryParams(queryParams);
      } else if (this.categoryItem?.category) {
        let styleCategories = getFilterCategoriesByPlpCategory(
          this.categoryItem?.category,
        );

        const distinctFilterCategories = await firstValueFrom(
          this.search
            .getDistinctFiltersByCategories(
              'ecom-styles',
              this.categoryItem?.category,
              styleCategories,
            )
            .pipe(catchError(this.handleError)),
        );

        this.filterCategories = distinctFilterCategories;

        this.styleService.filterCategories.next(this.filterCategories);

        this.setFilterCategoriesFromQueryParams(queryParams);
      }
    }

    return;
  }

  private async setShoesFilters(
    filters: FilterCategory[],
    colorFamily: FilterCategory,
    styleGroup: FilterCategory,
    product: FilterCategory,
  ) {
    filters.forEach((filter) => {
      if (filter.attribute === 'colorFamily') {
        if (colorFamily && colorFamily.options && filter.options) {
          colorFamily.options = [...colorFamily.options, ...filter.options];
          colorFamily.options = [
            ...new Map(
              colorFamily.options.map((option: FilterOption) => [
                option['value'],
                option,
              ]),
            ).values(),
          ];
        }
      }
      if (filter.attribute === 'styleGroup') {
        if (styleGroup.options && filter.options) {
          styleGroup.options = [...styleGroup.options, ...filter.options];
          styleGroup.options = [
            ...new Map(
              styleGroup.options.map((option: FilterOption) => [
                option['value'],
                option,
              ]),
            ).values(),
          ];
          this.filterCategories = [colorFamily, styleGroup, product];
          this.styleService.filterCategories.next([
            colorFamily,
            styleGroup,
            product,
          ]);
        }
      }
      if (filter.attribute === 'styleType') {
        if (product && product.options && filter.options) {
          product.options = [...product.options, ...filter.options];
          product.options = [
            ...new Map(
              product.options.map((option: FilterOption) => [
                option['value'],
                option,
              ]),
            ).values(),
          ];
          this.filterCategories = [colorFamily, product];
          this.styleService.filterCategories.next([colorFamily, product]);
        }
      }
    });
  }

  private async setSocksFilters(
    filters: FilterCategory[],
    colorFamily: FilterCategory,
    styleGroup: FilterCategory,
    product: FilterCategory,
  ) {
    filters.forEach((filter) => {
      if (filter.attribute === 'colorFamily') {
        if (colorFamily.options && filter.options) {
          colorFamily.options = [...colorFamily.options, ...filter.options];
          colorFamily.options = [
            ...new Map(
              colorFamily.options.map((option: FilterOption) => [
                option['value'],
                option,
              ]),
            ).values(),
          ];
        }
      }
      if (filter.attribute === 'styleType') {
        if (product.options && filter.options) {
          product.options = [...product.options, ...filter.options];
          product.options = [
            ...new Map(
              product.options.map((option: FilterOption) => [
                option['value'],
                option,
              ]),
            ).values(),
          ];
        }
      }
      if (filter.attribute === 'styleGroup') {
        if (styleGroup.options && filter.options) {
          styleGroup.options = [...styleGroup.options, ...filter.options];
          styleGroup.options = [
            ...new Map(
              styleGroup.options.map((option: FilterOption) => [
                option['value'],
                option,
              ]),
            ).values(),
          ];
          this.filterCategories = [colorFamily, styleGroup, product];
          this.styleService.filterCategories.next([
            colorFamily,
            styleGroup,
            product,
          ]);
        }
      }
    });
  }

  // setMetaTags(categoryItem: CategoryItem) {
  //   const baseHeaderTitle: string = environment.baseHeaderTitle;

  //   this.titleService.setTitle(
  //     baseHeaderTitle + ' - ' + categoryItem.displayName,
  //   );
  // }

  private async getStyles() {
    try {
      // this.styles = null;
      // this.filterCategories = [];
      // if (this.styleGroup !== StyleGroup.Unassigned && this.categoryItem) {
      if (this.categoryItem) {
        console.log('getStyles() > this.searchStyles()');
        await this.searchStyles();
      }
    } catch (error: any) {
      console.log(
        'An error occurred on getStyles() > listing-page.component.ts',
      );
    }
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    console.log('WE ARE HANDLING LISTING-PAGE OBSERVABLE ERROR');
    // console.log(err);
    // return throwError(() => err);
    return new Observable<never>();
  }

  private async searchStyles() {
    console.log(
      'searchStyles() > this.filterCategories',
      this.filterCategories,
    );
    this.styles = await firstValueFrom(
      this.search
        .style(this.getSearchCriteria())
        .pipe(
          map((elasticStyles) => {
            const styles: EcomStyle[] = [];
            const recastedElasticStylesType: any = elasticStyles.hits.total;
            // To fix later
            const resultsCount = recastedElasticStylesType.value;
            if (elasticStyles.hits.hits.length > 0) {
              this.emptyResults = false;
              this.resultSize = resultsCount;
              elasticStyles.hits.hits.forEach((style: any) => {
                styles.push(style._source as EcomStyle);
              });
            } else {
              this.emptyResults = true;
            }
            return styles;
          }),
        )
        .pipe(catchError(this.handleError)),
    );
    if (this.paginator && !this.emptyResults) {
      this.paginator.firstPage();
    }
  }

  private getSearchCriteria(): SearchCriteria[] {
    const searchCriteria: SearchCriteria[] = [];

    if (this.categoryItem) {
      if (this.categoryItem.category === Category.ShoesAndSocks) {
        // rental shoe override - will remove when
        // purchase-only product is enabled on ecom
        searchCriteria.push({
          attribute: 'categories',
          // values: [Category.Shoes, Category.Socks, Category.PurchaseShoes],
          values: [Category.Shoes, Category.Socks],
        });
      } else {
        searchCriteria.push({
          attribute: 'categories',
          values: [this.categoryItem.category],
        });

        // accessories override - include both rental and purchase
        // will remove if statement when purchase-only product is enabled on ecom
        // future state: search by category and styleGroup - no overrides
        if (this.categoryItem.category !== Category.Accessories) {
          // searchCriteria.push({
          //   attribute: 'styleGroup',
          //   values: [this.styleGroup],
          // });
        }
      }
    }

    this.filterCategories.forEach((category) => {
      const criteria: SearchCriteria = {
        attribute: category.attribute,
        values: [],
      };
      category.options?.forEach((option) => {
        if (option.selected) {
          criteria.values.push(option.value);
        }
      });
      if (criteria.values.length > 0) {
        searchCriteria.push(criteria as SearchCriteria);
      }
    });
    return searchCriteria;
  }

  public get filterSelect(): typeof FilterSelect {
    return FilterSelect;
  }

  public getImage(style: EcomStyle): Image {
    return getImage(style);
  }

  public getImageFromImageKit(style: EcomStyle): Image {
    // return getImage(style);
    if (environment.imageKitPLPEnabled) {
      return getImageKitImage(style);
    } else {
      return getImage(style);
    }
  }

  public showMobileFilters(): void {
    this.styleService.showFilter.next(true);
    this.navigationService.showPlpNav.next(true);
  }

  public getRentPrice(style: EcomStyle): number {
    return getStyleRentPrice(style) ?? 0;
  }

  public getBuyPrice(style: EcomStyle): number {
    return getStyleBuyPrice(style) ?? 0;
  }

  public hasDisplayPrice(ecomStyle: EcomStyle): boolean {
    return hasDisplayPrice(ecomStyle);
  }

  public isPurchaseOnly(ecomStyle: EcomStyle): boolean {
    return canOnlyBuyStyle(ecomStyle);
  }

  public displayRentText(ecomStyle: EcomStyle): string {
    if (canRentAndBuyStyle(ecomStyle)) {
      return 'AVAILABLE TO RENT OR BUY';
    } else {
      if (canOnlyRentStyle(ecomStyle)) {
        return 'AVAILABLE TO RENT';
      }
    }
    return '';
  }

  async change(event: PageEvent) {
    let paginatorOptions: PaginatorOptions = {
      previousPageIndex: event.previousPageIndex ?? undefined,
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
      length: event.length,
    };
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.styles = await firstValueFrom(
      this.search
        .style(this.getSearchCriteria(), paginatorOptions)
        .pipe(
          map((elasticStyles) => {
            const styles: EcomStyle[] = [];
            const recastedElasticStylesType: any = elasticStyles.hits.total;
            // To fix later
            const resultsCount = recastedElasticStylesType.value;
            if (elasticStyles.hits.hits.length > 0) {
              this.resultSize = resultsCount;
              elasticStyles.hits.hits.forEach((style: any) => {
                styles.push(style._source as EcomStyle);
              });
            }
            return styles;
          }),
        )
        .pipe(catchError(this.handleError)),
    );
    this.scrollToTop();
  }

  getStyleTitleAndCode(style: EcomStyle) {
    // Replace spaces with hyphens and make the string URL-safe
    if (style.marketingTitle !== undefined) {
      const title = encodeURIComponent(
        style.marketingTitle.toLowerCase().replace(/\s+/g, '-'),
      );
      const titleAndCode = `/style/${title}-${style.styleCode}`;
      return titleAndCode;
    } else {
      return `/style/${style.styleCode}`;
    }
  }

  getQueryStringParameters(activatedRoute: ActivatedRoute): {
    [key: string]: string;
  } {
    const queryParams = activatedRoute.snapshot.queryParams;
    return queryParams;
  }

  async setFilterCategoriesFromQueryParams(queryParams: Params) {
    // console.log('queryParams', queryParams);

    if (this.filterCategories !== undefined) {
      for (const key in queryParams) {
        const queryKey: string = key.toLowerCase();
        const queryFullValue: string = queryParams[key].toLowerCase();

        console.log('queryKey', queryKey);

        const queryValues: string[] = queryFullValue.split('|');

        this.filterCategories?.forEach((category) => {
          if (category.attribute.toLowerCase() === queryKey) {
            console.log('found matching key: ' + queryKey);
            queryValues.forEach((queryValue) => {
              category.options?.forEach((option) => {
                if (option.value.toLowerCase() === queryValue) {
                  console.log('found matching value: ' + queryValue);
                  option.selected = true;
                }
              });
            });
          }
        });
      }

      this.styleService.filterCategories.next(this.filterCategories);

      return;
    }
  }

  getParamsFromFilterCategories(filterCategories: FilterCategory[]) {
    let params: { [key: string]: string } = {};

    filterCategories.forEach((category) => {
      // category.options?.forEach((option) => {
      const filterOption = this.getParamValueForFilterOption(category.options);

      if (filterOption !== '') {
        params[category.attribute] = filterOption;
      }

      // if (option.selected) {
      //   params[category.attribute] = option.value;
      // }
      // });
    });

    return params;
  }

  getParamValueForFilterOption(options: FilterOption[] | undefined) {
    if (options === undefined) {
      return '';
    }

    // console.log('options', options);

    let paramValue = '';
    let index: number = 0;

    options.forEach((option) => {
      if (option.selected) {
        if (index > 0) {
          paramValue += '|';
        }
        paramValue += option.value;
        index++;
      }
    });

    return paramValue;
  }

  updateBrowserUrlWithParams(
    params: { [key: string]: string },
    preserveKeys: string[] = [],
  ) {
    // Get the current query parameters
    const currentParams = this.route.snapshot.queryParams;

    console.log('updateBrowserUrlWithParams', params);
    console.log('currentParams', currentParams);

    // Merge current query params with new params
    const mergedParams = { ...currentParams, ...params };

    // Remove keys from mergedParams that are not in params and not in preserveKeys
    Object.keys(mergedParams).forEach((key) => {
      if (!(key in params) && !preserveKeys.includes(key)) {
        delete mergedParams[key];
      }
    });

    console.log('cleanedParams', mergedParams);

    // Create a URL tree with the cleaned parameters
    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: mergedParams,
    });

    // Replace the current URL with the updated one
    this.router.navigateByUrl(urlTree);
  }

  private scrollToTop(): void {
    this.scrollService.scrollToAnchor('listing-anchor', [0, 5000]);
  }
}
