import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';

interface SearchForm {
  search: FormControl<string | null>;
}

@Component({
  selector: 'app-search-desktop',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchDesktopComponent {
  @ViewChild('input') input!: MatInput;

  form = new FormGroup<SearchForm>({
    search: new FormControl(),
  });
  expandInput = false;

  constructor(private router: Router) {}

  hasValue(): boolean {
    return !!this.form.controls.search.value;
  }

  onSearch(): void {
    if (this.hasValue()) {
      this.router.navigate([`/products`], {
        queryParams: { searchText: this.form.controls.search.value },
      });
      this.onClear();
    }
  }

  onClear(): void {
    this.form.setValue({ search: null });
  }

  onMouseEnter(): void {
    this.form.enable();
    setTimeout(() => this.input.focus(), 0);
    this.expandInput = true;
  }

  onMouseLeave(): void {
    if (!this.hasValue()) {
      this.form.disable();
      this.expandInput = false;
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Tab' && event.shiftKey === false) {
      this.form.enable();
      setTimeout(() => this.input.focus(), 0);
      this.expandInput = true;
    } else if (event.key === 'Tab' && event.shiftKey === true) {
      if (!this.hasValue()) {
        this.form.disable();
        this.expandInput = false;
      }
    }
  }

  inputKeydown(event: KeyboardEvent): void {
    if (event.key === 'Tab' && event.shiftKey === false) {
      if (!this.hasValue()) {
        this.form.disable();
        this.expandInput = false;
      }
    }
  }

  onBlur(): void {
    if (!this.hasValue()) {
      this.onMouseLeave();
    }
  }
}
