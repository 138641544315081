import { AfterContentInit, Component, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AnonAuthService,
  AuthService,
  CartService,
  EventService,
} from '@jfw-library/ecommerce/core';

@Component({
  selector: 'app-sign-in-v2',
  templateUrl: './sign-in-v2.component.html',
  styleUrls: ['./sign-in-v2.component.scss'],
})
export class SignInV2Component implements OnInit, AfterContentInit {
  error: string = '';
  verified: string = '';
  loadingLogin = signal(true);
  currentUser = toSignal(this.authService.user$);
  displayEmailPasswordForm = signal(false);
  isProdModeWideOpen = this.authService.isProdModeWideOpen;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private cartService: CartService,
    private anonAuthService: AnonAuthService,
    private router: Router,
  ) {
  }

  ngAfterContentInit(): void {}

  async ngOnInit() {

    console.log(
      '%cSignInV2Component_OnInit',
      'background-color:red;color:white',
    );

    if (!this.isProdModeWideOpen) {
      this.anonAuthService.clearAnonTokenCookies();
    }

    // console.log('V2login -> ngOnInit');
    await this.authService.signOut();
    this.cartService.clearCartCache();
    this.eventService.clearSelectedEvent();

    // currently these params are only added to the redirect url when prod_mode_authorization_rules is false.
    this.route.queryParams.subscribe((params) => {
      if (params.error === 'noaccess') {
        this.error = 'Your account does not have access.';
      }
      if (params.verified === 'false') {
        this.verified =
          'Your account has not been verified. Check your email.';
      }
      //this.loadingLogin = false;
    });

    this.loadingLogin.set(false);


    //}, 500);
    //}
  }

  showHideEmailPasswordForm() {
    this.displayEmailPasswordForm.update(val => !val);
  }

  async signInWithGoogle() {
    await this.authService.signInWithGoogle();
  }
}
