<div class="close-button">
  <button (click)="closeSidenav()" mat-icon-button>
    <mat-icon matListIcon>close</mat-icon>
  </button>
</div>

<mat-nav-list [@.disabled]="disableAnimation" class="mobile-header-nav-list">
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>Suits & Tuxedos</mat-expansion-panel-header>
      @for (
        item of rentalCategoryItems;
        track [item.route, item.displayName];
        let index = $index
      ) {
        @if (item.route === "suits-and-tuxedos") {
          <a
            mat-list-item
            (click)="suitsTuxedosNavigation('/ensembles/' + item.route)"
            >{{ item.displayName }}</a
          >
        } @else {
          <a
            mat-list-item
            (click)="closeSidenav()"
            [routerLink]="['styles', item.route]"
            >{{ item.displayName }}</a
          >
        }
      }
      <div class="collection-header">
        <strong>Rent Or Buy</strong>
      </div>
      <button
        mat-menu-item
        (click)="closeSidenav()"
        routerLink="/ways-to-rent-or-buy"
      >
        Ways to Rent Or Buy
      </button>
      <div class="collection-header">
        <strong>Special Sizes</strong>
      </div>
      <button mat-menu-item (click)="closeSidenav()" routerLink="/big-and-tall">
        Big & Tall
      </button>
      <button mat-menu-item (click)="closeSidenav()" routerLink="/kids">
        Kids
      </button>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>How It Works</mat-expansion-panel-header>
      @for (
        item of howItWorksNav;
        track [item.link, item.title];
        let index = $index
      ) {
        <a
          mat-list-item
          (click)="closeSidenav()"
          routerLink="{{ item.link }}"
          >{{ item.title }}</a
        >
      }
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>Inspiration</mat-expansion-panel-header>
      @for (
        item of inspirationNav;
        track [item.link, item.title];
        let index = $index
      ) {
        @if (item.linkType === linkType.Route) {
          <a
            mat-list-item
            (click)="closeSidenav()"
            routerLink="{{ item.link }}"
            >{{ item.title }}</a
          >
        }
        @if (item.linkType === linkType.Url) {
          <a
            mat-list-item
            (click)="closeSidenav()"
            href="{{ item.link }}"
            [target]="item.target"
            >{{ item.title }}</a
          >
        }
      }
    </mat-expansion-panel>

    <mat-list-item (click)="closeSidenav()" class="top-link">
      <a routerLink="/store-locator" routerLinkActive="active">Find a Store</a>
    </mat-list-item>

    <mat-list-item class="top-link">
      <a href="https://shop.jimsformalwear.com/" target="_blank">
        <span>BUY</span>
      </a>
    </mat-list-item>

    @if (!isLoggedIn() && !hideGetStarted) {
      <mat-list-item (click)="getStarted()" class="top-link">
        <a routerLinkActive="active">Get Started</a>
      </mat-list-item>
    }

    @if (isLoggedIn()) {
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>My Account</mat-expansion-panel-header>

        @for (
          navListItem of myAccountNav;
          track [navListItem.link, navListItem.title]
        ) {
          <a
            mat-list-item
            (click)="closeSidenav()"
            routerLink="{{ navListItem.link }}"
            >{{ navListItem.title }}</a
          >
        }

        <a mat-list-item (click)="onSignOut(); closeSidenav()">Log Out</a>
      </mat-expansion-panel>
    }

    @if (alphaMode) {
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>Alpha</mat-expansion-panel-header>
        @for (
          navListItem of alphaNav;
          track [navListItem.link, navListItem.title]
        ) {
          <a
            mat-list-item
            (click)="closeSidenav()"
            routerLink="{{ navListItem.link }}"
            >{{ navListItem.title }}</a
          >
        }
      </mat-expansion-panel>
    }
  </mat-accordion>
</mat-nav-list>
