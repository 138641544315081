<mat-accordion [multi]="true">
  <ng-container *ngIf="filterCategories">
    <ng-container *ngFor="let category of this.filterCategories; let i = index">
      <hr />
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ category.displayName }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div
            class="checkbox-container"
            *ngFor="let option of category.options; let iOption = index"
          >
            <mat-checkbox
              [tabIndex]="iOption"
              #elementRef
              [ngClass]="
                category.attribute === 'colorFamily'
                  ? setColorClass(option.value, elementRef)
                  : ''
              "
              (click)="optionClicked(category, i, iOption)"
              [checked]="option.selected"
              [(ngModel)]="option.selected"
              >{{ rentOrBuyMask(option.value) }}</mat-checkbox
            >
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>
  </ng-container>
</mat-accordion>
