import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SEOService } from '../../services/seo/seo-service';

type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};

interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class StyleCategoryResolver implements Resolve {
  private seoService = inject(SEOService);
  constructor() {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const category = route.paramMap.get('category');
    const metaData: MetaInfo = {
      metaTitle: category ?? undefined,
      metaKeywords: undefined,
      metaDescription: undefined,
      noIndex: true,
    };

    switch (category) {
      case 'shirts':
        metaData.metaTitle = 'Dress Shirts';
        metaData.metaDescription =
          'Discover the epitome of elegance with our premium dress shirts. Impeccable craftsmanship, timeless styles, and superior comfort. Upgrade your wardrobe today!';
        metaData.noIndex = false;
        break;
      case 'ties':
        metaData.metaTitle = 'Ties and Bowties';
        metaData.metaDescription =
          'Elevate your style with our exquisite ties. Explore a collection of 400 colors and modern patterns for the perfect finishing touch. Shop now and redefine your look.';
        metaData.noIndex = false;
        break;
      case 'vests-and-cummerbunds':
        metaData.metaTitle = 'Vests and Cummerbunds';
        metaData.noIndex = false;
        metaData.metaDescription =
          'Elevate your formal look with stylish vests and cummerbunds. Explore classic and contemporary designs to find the perfect accessory. Shop now and redefine your style!';
        break;
      case 'shoes-and-socks':
        metaData.metaTitle = 'Dress Shoes and Socks';
        metaData.noIndex = false;
        metaData.metaDescription =
          'Step up your style game with our trendy collection of shoes and socks. Explore a variety of designs and materials for a fashionable and comfortable look. Shop now!';
        break;

      case 'cufflinks-and-studs':
        metaData.metaTitle = 'Cufflinks and Studs';
        metaData.noIndex = false;
        metaData.metaDescription =
          'The smallest details matter so enhance your formal attire with our elegant cufflinks and studs for the perfect finishing touch. Shop now to elevate your look!';
        break;

      case 'accessories':
        metaData.metaTitle = 'Formal Wear Accessories';
        metaData.noIndex = false;
        metaData.metaDescription =
          'Complete your formal look with our exquisite suit and tuxedo accessories. Shop now for stylish ties, cufflinks, and pocket squares. Elevate your style!';
        break;
      default:
        metaData.noIndex = true;
        break;
    }

    // Check if it is a parameterized route (we do not index paramterized routes)
    const { queryParams } = route;
    const isParameterizedRoute = Object.keys(queryParams).length > 0;

    if (isParameterizedRoute) {
      this.seoService.setNoIndexTag(true);
      /**
       * If noIndex is ever true, canonical should be removed
       * Default remove canonical.
       *  */
      this.seoService.setCanonicalTag(state.url, false);
    } else {
      this.seoService.setNoIndexTag(metaData?.noIndex ?? undefined);
      this.seoService.setCanonicalTag(state.url, !metaData.noIndex);
    }
    this.seoService.setMetaTags(metaData);
    // route.data = { seoParams: metaData };
  }
}
